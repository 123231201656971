'use strict';

import axios from '../../../utils/axios';

export const getWeatherConfig = async () => {
    let params = {
        appName: 'common-weather-service'
    };
    const data = await axios.get(`/api/ops/config/common/page/configGet`, { params });
    return {
        jdForm: { jdAppkey: data['weather.jingdong.appkey'], jdUrl: data['weather.jingdong.url'] },
        hfForm: {
            hfCityUrl: data['weather.hefeng.cityidurl'],
            hfUrl: data['weather.hefeng.url'],
            hfAppkey: data['weather.hefeng.appkey']
        },
        xzForm: { xzUrl: data['weather.xinzhi.url'], xzAppkey: data['weather.xinzhi.appkey'] },
        defaultKey: data['weather.common.defaultChannel']
    };
};

export const updateWeatherConfig = async (form) => {
    const map = {
        'weather.jingdong.appkey': form.jdForm.jdAppkey,
        'weather.jingdong.url': form.jdForm.jdUrl,
        'weather.hefeng.cityidurl': form.hfForm.hfCityUrl,
        'weather.hefeng.url': form.hfForm.hfUrl,
        'weather.hefeng.appkey': form.hfForm.hfAppkey,
        'weather.xinzhi.url': form.xzForm.xzUrl,
        'weather.common.defaultChannel': form.defaultKey || 'JDWX',
        'weather.xinzhi.appkey': form.xzForm.xzAppkey
    };
    let params = {
        appName: 'common-weather-service',
        map
    };
    return await axios.post(`/api/ops/config/common/page/configUpdate`, params);
};

export const testWeather = async () => {
    const res = await axios.get(`/api/ops/config/common/test/weather`);

    return res;
};
