<!--
 * @Author: your name
 * @Date: 2020-09-04 14:20:17
 * @LastEditTime: 2020-09-14 11:54:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /isc-desktop-ui/src/components/common/core-sidebar.vue
-->
<template>
    <div class="sidebar" ref="sidebar">
        <ul class="pb20" style="max-height: 90vh; overflow: auto">
            <li v-for="(item, index) in formattedAppList" :key="index">
                <div class="label">
                    <span>{{ item.label }}</span>
                </div>
                <div
                    @click="jumpUrl(app)"
                    class="pl20 sidebar-item mb16 greyff"
                    v-for="(app, innerIndex) in item.apps"
                    :key="innerIndex"
                >
                    <div
                        @drag="drag($event, app)"
                        @dragend="dragend($event)"
                        class="droppable-element fz14 ml12"
                        draggable="true"
                        unselectable="on"
                    >
                        {{ app.name }}
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
// import draggable from 'vuedraggable';
import xyData from '@/plugins/data';
import localApps from '@/common/link-apps';
import { bus } from '../../bus/bus';

let mouseXY = { x: null, y: null };
let DragPos = { x: null, y: null, w: 1, h: 1, i: null };

export default {
    data() {
        return {
            xyData: xyData,
            colorList: [
                'rgba(13, 44, 80, 0.95)',
                'rgba(0, 82, 155, 0.95)',
                'rgba(219, 85, 45, 0.95)',
                'rgba(116, 116, 116, 0.95)'
            ],
            sizeList: ['mini', 'midle', 'large'],
            layout: [
                { x: 0, y: 0, w: 2, h: 2, i: '0' },
                { x: 2, y: 0, w: 2, h: 1, i: '1' },
                { x: 2, y: 1, w: 2, h: 1, i: '2' },
                { x: 0, y: 2, w: 2, h: 2, i: '3' },
                { x: 2, y: 2, w: 2, h: 2, i: '4' },
                { x: 4, y: 0, w: 2, h: 3, i: '5' },
                { x: 4, y: 3, w: 2, h: 1, i: '6' }
            ]
        };
    },
    // components: { draggable },
    mounted() {
        document.addEventListener(
            'dragover',
            function (e) {
                mouseXY.x = e.clientX;
                mouseXY.y = e.clientY;
            },
            false
        );
    },
    computed: {
        appList() {
            if (process.env.NODE_ENV === 'development') {
                return [...localApps, ...this.$store.state.app.user.appList];
            }
            return this.$store.state.app.user.appList;
        },
        formattedAppList() {
            let temp = {};
            this.appList.forEach((app) => {
                if (!temp[app.surName]) {
                    temp[app.surName] = [];
                }
                temp[app.surName].push(app);
            });
            let tempArr = [];
            Object.keys(temp).forEach((key) => {
                tempArr.push({
                    label: key,
                    apps: temp[key]
                });
            });
            return tempArr;
        }
    },
    methods: {
        drag(e, val) {
            bus.$emit('drag', [e, val]);
        },
        dragend(e) {
            bus.$emit('dragend', e);
        },
        jumpUrl(app) {
            this.$appManager.openApp(app.code);

            // return app.type === 3 ? '/app/' + app.code : '/' + app.code;
        },
        dealDragList() {
            return Object.values(this.$store.state.drag.dragDataList).map((item) => {
                return `${item.x}-${item.y}`;
            });
        },
        createdBlock(data, xyDetail) {
            let [x, y] = xyDetail.split('-');
            // 随机创建一个block模块
            return {
                tile: {
                    name: data.name,
                    bgcolor: this.colorList[Math.floor(Math.random() * this.colorList.length)],
                    color: 'rgb(255,255,255)',
                    icon: data.logo || '/desktop/icon/default.png'
                },
                code: data.code,
                size: 'mini',
                // size: this.sizeList[Math.floor(Math.random() * this.sizeList.length)],
                x,
                y
            };
        },
        addBlockToDesktop(app) {
            let xyDetail = this.xyData.filter((el) => !this.dealDragList().includes(el)); // 计算剩余位置取第一个
            // 拖拽完成往列表里面加数据
            if (
                !Object.keys(this.$store.state.drag.dragDataList) ||
                !Object.keys(this.$store.state.drag.dragDataList).includes(app.code)
            ) {
                // 不存在则加入
                if (xyDetail.length !== 0) {
                    this.$store.commit('drag/setDragDataList', {
                        ...this.$store.state.drag.dragDataList,
                        [app.code]: this.createdBlock(app, xyDetail[0])
                    });
                    window.localStorage.setItem(
                        'dragList',
                        JSON.stringify({
                            ...this.$store.state.drag.dragDataList,
                            [app.code]: this.createdBlock(app, xyDetail[0])
                        })
                    );
                }
            }
        },
        onDraggingStart() {
            this.$store.commit('drag/setDraging', true);
        },
        onDraggingEnd(event, app) {
            this.addBlockToDesktop(app);
            if (event.originalEvent.pageY > 400 && event.originalEvent > 200) {
                this.addBlockToDesktop(app);
            }
            this.$store.commit('drag/setDraging', false);
        }
    }
};
</script>
<style lang="scss" scoped>
$topMixBottom: 90px;
.sidebar {
    width: 210px;
    position: absolute;
    z-index: 2;
    overflow: hidden;
    background: rgba(36, 37, 44, 1);
    left: -39px;
    top: -1px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    &-item {
        width: 100%;
        height: 38px;
        line-height: 38px;
        cursor: pointer;
        &:hover {
            background: #3a3a3a;
        }
    }
}
::v-deep .vdr {
    border: none;
}
.label {
    height: 38px;
    line-height: 38px;
    color: #fff;
    font-size: 14px;
    margin-left: 30px;
}
</style>
