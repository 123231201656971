<template>
    <div class="header d-flex px20 align-center fz16">
        <!--        <span class="header__menu__text">菜单</span>-->
        <isc-dropdown class="header__menu d-flex align-center" placement="bottom" @visible-change="visibleChange">
            <div class="d-flex align-center">
                <!--<img class="header__menu__logo" :src="logo" :style="{ width: isLogoExtra ? '72px' : '24px' }" />-->
                <span class="header__menu__text">菜单</span>
            </div>
            <isc-dropdown-menu class="header__menus" slot="dropdown" :visible-arrow="false">
                <sidebar></sidebar>
            </isc-dropdown-menu>
        </isc-dropdown>

        <div class="mx20 divider"></div>

        <isc-tooltip content="点击回到桌面">
            <div class="header__brand d-flex align-center" @click="changeTab('/homepage')">
                <img
                    v-if="$store.state.app.user.companyDetail"
                    class="header__brand__logo"
                    alt=""
                    :src="$store.state.app.user.companyDetail.logo || require('../../assets/svg/company-logo.svg')"
                />
                <span class="header__brand__text"></span>
                <span v-if="$store.state.app.user.companyDetail" class="header__brand__text">
                    {{ formatterName($store.state.app.user.companyDetail.enterprise) }}
                </span>
            </div>
        </isc-tooltip>

        <transition-group name="flip-list" tag="div" class="header__tabs">
            <div
                v-for="tab in tabList"
                :key="tab.code"
                class="header__tabs__item"
                @click="tabClick(tab)"
                :class="currentTabCode === tab.code ? 'active' : ''"
            >
                <div class="activator">
                    <div class="name text-truncate">
                        {{ tab.name }}
                    </div>
                    <i class="isc-icon-bold-close del" @click.stop="deleteTab(tab)"></i>
                </div>
                <div class="bottom-bg"></div>
            </div>
        </transition-group>

        <div class="header__right">
            <isc-badge is-dot :hidden="noReadNum === 0" class="header__msg">
                <span @click="$refs.coreDrawer.open(noReadNum)">通知</span>
            </isc-badge>
            <isc-dropdown class="settings mr16" placement="bottom">
                <span class="settings__text">设置</span>
                <isc-dropdown-menu class="settings__dropdown dropmenu" :visible-arrow="false">
                    <isc-dropdown-item @click.native="showModal">壁纸管理</isc-dropdown-item>
                    <isc-dropdown-item v-if="commonConfig" @click.native="$emit('settings')"
                        >公共设置</isc-dropdown-item
                    >
                    <isc-dropdown-item @click.native="openHelp">帮助中心</isc-dropdown-item>
                </isc-dropdown-menu>
            </isc-dropdown>

            <isc-dropdown placement="bottom-end" class="userinfo">
                <span class="userinfo__text text-right">
                    <!-- {{
                        $store.state.app.user.rentalNickName
                            ? formatterName($store.state.app.user.rentalNickName) || '无组织'
                            : '无组织'
                    }} -->
                    {{ userName }}<i class="isc-icon-arrow-down isc-icon--right"></i>
                </span>
                <isc-dropdown-menu slot="dropdown" class="userinfo__dropdown dropmenu" :visible-arrow="false">
                    <isc-dropdown-item @click.native="dialogFormVisible = true" style="width: 100px"
                        >修改密码</isc-dropdown-item
                    >
                    <isc-dropdown-item @click.native="logout" style="width: 100px">退出</isc-dropdown-item>
                </isc-dropdown-menu>
            </isc-dropdown>
        </div>

        <isc-dialog
            :before-close="beforeClose"
            :visible.sync="dialogVisible"
            class="wallpaper-dialog"
            width="540px"
            top="calc(50vh - 200px)"
            append-to-body
            center
        >
            <div class="wallpaper-dialog__items d-flex align-center justify-space-between">
                <div
                    class="wallpaper-dialog__item d-flex flex-column align-center"
                    v-for="(item, index) in bgImgList"
                    :key="index"
                >
                    <img class="wallpaper-dialog__image" @click="setBgImg(item.indexTag)" :src="item.src" alt="" />
                    <div class="wallpaper-dialog__title">{{ item.title }}</div>
                </div>
            </div>
        </isc-dialog>

        <isc-dialog title="修改密码" :visible.sync="dialogFormVisible" width="350px" :close-on-click-modal="false">
            <isc-form label-position="right" ref="form" :model="form" :rules="rules" label-width="80px">
                <isc-form-item label="旧密码" prop="oldPassword" required>
                    <isc-input type="password" v-model="form.oldPassword" autocomplete="off"></isc-input>
                </isc-form-item>
                <isc-form-item label="密码" prop="password" required>
                    <isc-input type="password" v-model="form.password" autocomplete="off"></isc-input>
                </isc-form-item>
                <isc-form-item label="确认密码" prop="checkPassword" required>
                    <isc-input type="password" v-model="form.checkPassword" autocomplete="off"></isc-input>
                </isc-form-item>
            </isc-form>
            <div slot="footer" class="dialog-footer">
                <isc-button type="primary" @click="updatePassword">确 认</isc-button>
                <isc-button @click="dialogFormVisible = false" plain>取 消</isc-button>
            </div>
        </isc-dialog>
        <!-- 通知抽屉 -->
        <core-drawer ref="coreDrawer" @reloadnum="reloadnumFun"></core-drawer>
    </div>
</template>

<script>
import { queryRental, getUserInfo, changePwd, unReadCount } from '../../service/common';
import { getBasicConfig } from '../../views/common-settings/basic-config/service';
import { logout } from '../../service/system';
import sidebar from './core-sidebar';
import coreDrawer from './core-drawer.vue';
import { desk } from '@isyscore/messenger';
import { va } from '@isyscore/classes/validation';
import { error, success } from '../../utils/message';

export default {
    name: 'CoreToolbar',
    data: () => ({
        drag: false,
        tabList: [],
        currentTab: {},
        drawer: true,
        dialogVisible: false,
        app: {},
        bgImgList: window.ENVS.nengxinanFeat
            ? [
                  {
                      indexTag: 4,
                      title: '金属之光',
                      src: require('../../assets/bgimgSetting/background4.png')
                  },
                  {
                      indexTag: 5,
                      title: '绿光之遂',
                      src: require('../../assets/bgimgSetting/background5.png')
                  }
              ]
            : [
                  // 后期可以改成动态的
                  {
                      indexTag: 1,
                      title: '科技蓝',
                      src: require('../../assets/bgimgSetting/background1.jpg')
                  },
                  {
                      indexTag: 2,
                      title: '星海钴',
                      src: require('../../assets/bgimgSetting/background2.jpg')
                  },
                  {
                      indexTag: 3,
                      title: '宇宙墨',
                      src: require('../../assets/bgimgSetting/background3.jpg')
                  }
              ],
        currentTabCode: '',
        dialogFormVisible: false,
        form: {
            oldPassword: '',
            password: '',
            checkPassword: ''
        },
        rules: {
            oldPassword: va.required().flex(5, 18).trigger('blur'),
            password: va.required().flex(5, 18).trigger('blur'),
            checkPassword: va.required().flex(5, 18).same('password', '确认密码不正确').trigger('blur')
        },
        noReadNum: 0
    }),
    components: {
        sidebar,
        coreDrawer
    },
    async created() {
        this.initRental();
        this.initStorage();
        this.queryMsg();
    },
    mounted() {
        // 初始化标题
        this.title = this.$route.name;
        this.$appManager.on('current-app-change', this.onCurrentAppChange);
        this.$appManager.on('opened-app-change', this.onAppListChange);
    },
    beforeDestroy() {
        this.$appManager.off('current-app-change', this.onCurrentAppChange);
        this.$appManager.off('opened-app-change', this.onAppListChange);
    },

    computed: {
        isLogoExtra() {
            return !!window.ENVS.nengxinanFeat;
        },
        logo() {
            if (window.ENVS.nengxinanFeat) {
                return require('../../assets/svg/logo-nengxinan.svg');
            } else {
                return require('../../assets/svg/company-logo.svg');
            }
        },
        userName: {
            get: function () {
                return this.$store.state.app.user.name;
            }
        },
        dragOptions() {
            return {
                animation: 200,
                group: 'tabList',
                disabled: false,
                ghostClass: 'ghost'
            };
        },

        isHomePage() {
            return this.$route.name === 'homepage';
        },
        commonConfig() {
            return this.$store.state.app.commonConfig;
        }
    },

    methods: {
        reloadnumFun(numObj) {
            this.noReadNum = numObj.all;
        },
        async queryMsg() {
            let count;
            try {
                count = await unReadCount();
            } catch (err) {
                console.log(err);
                return;
            }
            this.noReadNum = count.all || 0;
        },
        updatePassword() {
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    try {
                        await changePwd(this.form.oldPassword, this.form.password);
                        success('修改成功');
                        this.dialogFormVisible = false;
                    } catch (error) {
                        return;
                    }
                } else {
                    return false;
                }
            });
        },
        async initRental() {
            let companyDetail;
            let rental;

            try {
                companyDetail = await getBasicConfig();
                // rental = await queryRental();
            } catch (err) {
                return;
            }

            // const { rentalNickName } = rental;
            this.$store.commit('app/setUser', {
                companyDetail,
                // rentalNickName,
                ...this.$store.state.app.user
            });
        },
        initStorage() {
            try {
                this.tabList = JSON.parse(desk.sessionStorage.getItem('openedApps'));
            } catch (e) {
                console.error(e);
                this.tabList = [];
            }
            this.currentTabCode = desk.sessionStorage.getItem('currentApp') || '';
        },
        onCurrentAppChange(ev) {
            this.currentTabCode = ev;
        },
        onAppListChange(ev) {
            this.tabList = ev;
        },
        visibleChange(tp) {
            if (tp) {
                getUserInfo();
            }
        },
        formatterName(name) {
            if (name && name.length > 8) {
                return name.slice(0, 6) + '…';
            }
            return name || 'ISC-OS';
        },
        tabClick(tab) {
            this.$appManager.changeTab(tab.code);
        },
        changeTab(to) {
            if (this.$route.path !== to) {
                this.$router.push({ path: to });
            }
        },
        deleteTab(item) {
            this.$appManager.closeApp(item.code);
        },
        // 改变当前背景图
        setBgImg(item) {
            this.restSetItem('bgImg', item);
        },
        showModal() {
            this.dialogVisible = true;
            this.$store.commit('drag/setHelpShow', false);
        },
        openHelp() {
            window.open('https://open.isyscore.com/document/');
        },
        beforeClose(done) {
            this.dialogVisible = false;
            this.$store.commit('drag/setHelpShow', true);
            done();
        },
        async logout() {
            try {
                await logout();
            } catch (err) {
                return this.$message.error('登出失败：' + err);
            }
            desk.sessionStorage.clear();
            const reten = encodeURIComponent(`${location.origin}/desktop`);
            location.assign(`${ENVS.ssoUrl}?return=${reten}`);
        }
    }
};
</script>

<style lang="scss" scoped>
.header {
    height: 60px;
    background-color: #13171c;
    color: $app-grey-ff;
    line-height: 1;
    box-sizing: border-box;

    &__menu {
        cursor: pointer;
        height: 100%;

        &__logo {
            height: 24px;
            width: 24px;
        }

        &__text {
            color: #ffffff;
            font-size: 16px;
            margin-left: 8px;
            line-height: 60px;
        }
    }

    &__brand {
        cursor: pointer;
        margin-right: 60px;

        &__logo {
            height: 24px;
            width: 24px;
        }

        &__text {
            color: #ffffff;
            font-size: 16px;
            margin-left: 8px;
            white-space: nowrap;
            display: inline-block;
            max-width: 135px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    &__right {
        display: flex;
        align-items: center;
    }
    &__msg {
        margin-right: 20px;
        cursor: pointer;
    }
}

.settings {
    cursor: pointer;
    height: 100%;

    &__text {
        color: #ffffff;
        font-size: 16px;
        margin-left: 8px;
        line-height: 60px;
    }
}

.userinfo {
    cursor: pointer;
    height: 100%;

    &__text {
        color: #ffffff;
        font-size: 16px;
        margin-left: 8px;
        white-space: nowrap;
        line-height: 60px;
        display: inline-block;
    }
}

.divider {
    width: 1px;
    height: 14px;
    background: #f9f9f9;
    opacity: 0.49;
}

.dropmenu {
    background-color: #1a1a1a;
    min-height: 50px;
    border: none;

    ::v-deep {
        .isc-dropdown-menu__item {
            color: #fff;

            &:hover {
                background-color: #3a3a3a;
                color: #fff;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
            }
        }
    }
}

.header__tabs {
    height: 60px;
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    flex-wrap: nowrap;
    &__item {
        display: inline-flex;
        flex-grow: 1;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        transition: all 0.3s;
        cursor: pointer;
        flex-direction: column;
        padding: 0 9px 0 12px;
        max-width: 140px;
        position: relative;
        min-width: 70px;
        color: #666666;
        bottom: 1px;
        background: #24252c;
        box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.5);
        border-radius: 2px 2px 0 0;
        margin-right: 1px;
        &.active {
            background-color: rgba(255, 255, 255, 0.29);
            color: rgba(102, 102, 102, 1);
            .name {
                color: rgba(255, 255, 255, 1);
            }
            .bottom-bg {
                display: block;
                background: linear-gradient(180deg, rgba(83, 83, 91, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
            }
            &:hover {
                .del {
                    visibility: visible;
                }
            }
        }
        &:hover:not(.active) {
            background: rgba(61, 61, 71, 1);
            .del {
                visibility: visible;
            }
            .name {
                color: rgba(255, 255, 255, 1);
            }
            .bottom-bg {
                display: block;
                background: linear-gradient(180deg, rgba(83, 83, 91, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
            }
        }
        .bottom-bg {
            display: none;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 14px;
            background-color: rgba(36, 37, 44, 1);
            filter: blur(0px);
        }
        .activator {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 38px;
            background: transparent;
            width: 100%;
        }
        .name {
            font-size: 14px;
            line-height: 14px;
            width: 0;
            flex: 1;
            color: #666;
        }
        .icon {
            width: 22px;
            height: 22px;
            margin: 4px 4px 4px 4px;
        }
        .del {
            transition: 0.3s;
            font-size: 14px;
            line-height: 18px;
            width: 18px;
            text-align: center;
            margin: 8px 0 8px 4px;
            border-radius: 9px;
            visibility: hidden;
            &:hover {
                background-color: rgba(255, 255, 255, 0.29);
            }
        }
    }
}

.flip-list-move {
    transition: transform 0.5s;
}

.wallpaper-dialog {
    ::v-deep {
        .isc-dialog {
            background: rgba(0, 0, 0, 0.5);
        }

        .isc-dialog__header {
            padding: 0;
        }

        .isc-dialog__headerbtn {
            top: 9px;
            right: 9px;

            &:focus,
            &:hover {
                background: rgba(0, 0, 0, 0.5);
            }
        }

        .isc-dialog__body {
            padding: 60px 30px 0;
        }
    }

    &__items {
        padding-bottom: 34px;
    }

    &__image {
        width: 120px;
        height: 80px;
        margin-bottom: 12px;
        cursor: pointer;

        &:hover {
            outline: 1px solid #fff;
        }
    }

    &__title {
        color: white;
        text-align: center;
        line-height: 12px;
        font-size: 12px;
    }
}
::v-deep .isc-icon-arrow-down {
    display: none;
}
</style>

<style lang="scss">
.header__menus,
.settings__dropdown,
.userinfo__dropdown {
    margin: 0 !important;
    background: rgba(36, 37, 44, 1) !important;
    padding: 0 !important;
    min-height: 36px !important;
}
</style>
