<template>
    <form class="email-config-wrapper">
        <isc-form :model="form" label-width="100px" label-position="left">
            <isc-form-item label="模板名称">
                <isc-input></isc-input>
            </isc-form-item>
            <isc-form-item label="Key">
                <isc-input></isc-input>
            </isc-form-item>
            <isc-form-item label="内容"> </isc-form-item>
        </isc-form>
    </form>
</template>

<script>
export default {
    name: 'Index',
    data() {
        return {
            form: {
                account: '',
                password: '',
                protocol: '',
                domain: '',
                tableData: [
                    {
                        name: '1',
                        key: '1',
                        content: '若一段文字信息被省略了一部分时，鼠标Hover该段信息时，应用Tootip将完整信息展现出来',
                        operation: '1'
                    },
                    {
                        name: '1',
                        key: '1',
                        content: '1',
                        operation: '1'
                    }
                ]
            }
        };
    },
    methods: {
        handleAvatarSuccess(res, file) {
            if (res.code === 200) {
                this.imageUrl = res.data;
            } else {
                this.imageUrl = '';
            }
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        }
    }
};
</script>

<style lang="scss">
.email-config-wrapper {
    width: 100%;
    .isc-form {
        .form-title {
            font-weight: 600;
            ::v-deep .isc-form-item__label {
                font-size: 16px;
            }
        }

        width: 400px;
        &-item {
            ::v-deep .isc-form-item__label {
                color: #333333 !important;
            }

            .isc-textarea {
                ::v-deep .isc-textarea__inner {
                    height: 95px;
                    border: none;
                    background-color: #f5f5f5;
                }
            }

            .isc-button--text {
                ::v-deep i {
                    font-size: 16px;
                }
            }
        }
    }
}

.templateMsgBox {
    width: auto;
}
</style>
