<!--
 * @Author: your name
 * @Date: 2020-09-02 16:40:17
 * @LastEditTime: 2020-09-08 16:20:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /isc-desktop-ui/src/components/app/app-layout.vue
-->
<template>
    <div id="app" class="app flex-grow-1 d-flex flex-column" :class="`bg${bgindex}`">
        <header style="min-width: 1440px; max-width: 100vw">
            <slot name="header"></slot>
        </header>

        <main class="flex-grow-1 d-flex">
            <slot name="sidebar"></slot>
            <slot name="contain"></slot>
        </main>
    </div>
</template>

<script>
export default {
    name: 'AppLayout',
    data: () => ({
        bgindex: window.localStorage.getItem('bgImg') || (window.ENVS.nengxinanFeat ? 4 : 1)
    }),
    mounted() {
        window.addEventListener('setItem', () => {
            // 初始化监听storage的变化 其实只需要绑定一次就够了待 优化
            this.bgindex = window.localStorage.getItem('bgImg');
        });
    }
};
</script>

<style scoped lang="scss">
.app {
    position: relative;

    &__header {
        padding-top: 30px;
    }

    &__nav {
        font-size: 20px;
        height: 60px;

        a {
            margin: 0 20px;
        }
    }

    &__main {
        width: 1000px;
    }

    &__footer {
        font-size: 12px;
        color: #000;
        font-weight: 400;
        text-align: center;
        line-height: 30px;
        letter-spacing: 2.5px;
    }
}
main {
    //background: white;
}
// 定义 n种背景图
@for $i from 1 through 3 {
    .bg#{$i} {
        background-image: url('../../assets/bgimgSetting/background' + $i + '.jpg');
        background-size: cover;
        background-position: center center;
    }
}
</style>
