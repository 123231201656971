'use strict';

import { pathJoin } from '@isyscore/utils/path';
import { desk } from '@isyscore/messenger';
import { logout } from '@/service/system';
import router from '../router';
import store from '../store';
import { error } from '@/utils/message';
import axios from '@/utils/axios';
import { Message } from '@isyscore/base-ui';
import { qsParse, qsStringify } from '@isyscore/utils/qs';
import AppManager from '@/utils/app-manager';
import { reporter } from '@/plugins/reporter';

const standard = () => {
    const { receive } = desk;

    // navigator channel
    receive('navigator:home', async () => {
        store.commit('app/toolbar', true);
        await router.push({
            path: '/homepage'
        });
    });
    receive('navigator:loginPage', async () => {
        const reten = encodeURIComponent(`${location.href}`);
        location.assign(`${ENVS.ssoUrl}?return=${reten}`);
    });
    receive('navigator:app', async ({ appCode, to }) => {
        // if (appCode === 'permission-manage') {
        //     return await router.push({
        //         path: pathJoin('/', appCode, path),
        //         query
        //     });
        // }
        // TODO 看看是不是在应用的页面
        AppManager.getInstance().openApp(appCode, to);
    });
    receive('navigator:replaceApp', async ({ appCode, path, query }) => {
        AppManager.getInstance().replaceApp(appCode);
    });

    // 刷新应用菜单
    receive('navigator:refresh', async () => {
        try {
            store.state.app.user.appList = await axios.get('/api/ops/config/user/desktop/getMenuList');
        } catch (e) {
            error('刷新应用菜单失败：' + e);
        }
    });

    // user channnel
    receive('user:login', async () => {
        const reten = encodeURIComponent(location.href);
        location.assign(`${ENVS.ssoUrl}?return=${reten}`);
    });
    receive('user:logout', async () => {
        try {
            await logout();
        } catch (e) {
            return error('登出失败：' + e);
        }
        desk.sessionStorage.clear();
        const reten = encodeURIComponent(`${location.origin}/desktop`);
        location.assign(`${ENVS.ssoUrl}?return=${reten}`);
    });

    // topBar channel
    receive('topBar:show', () => store.commit('app/setToolbar', true));
    receive('topBar:hide', () => store.commit('app/setToolbar', false));
    receive('license:invalid', () => {
        Message.warning('OS未授权，请联系供应商');
        AppManager.getInstance().openApp('system-manage', '/about-os');
        router.push('/os/system-manage?appN=ossystem-manage&appT=history&appU=%2Faboutos');
    });

    receive('report:userAction', (visitAction) => {
        reporter.record(visitAction);
    });

    receive('navigator:url', (payload) => {
        addQuery(payload);
    });

    receive('unknown', (payload, ev) => {
        console.log('未知命令', ev.data);
    });
};

const _historyWrap = function (type) {
    const orig = history[type];
    const e = new Event(type);
    return function () {
        const rv = orig.apply(this, arguments);
        e.arguments = arguments;
        window.dispatchEvent(e);
        return rv;
    };
};
history.replaceState = _historyWrap('replaceState');

/**
 * 响应 iframe 内 url 的变化
 * @param routeObj route 信息，包含 url、type、base 三部分，base 是应用 code
 */
function addQuery(routeObj) {
    const queryObj = qsParse(location.search);
    let currentAppN = queryObj.appN?.replaceAll('/', '');
    let newAppN = routeObj.base?.replaceAll('/', '');
    if (currentAppN && newAppN && currentAppN !== newAppN) {
        return;
    }

    if (routeObj.url.endsWith('/')) {
        return;
    }
    queryObj.appN = newAppN;
    queryObj.appT = routeObj.type;
    queryObj.appU = routeObj.url;

    const search = qsStringify(queryObj);
    const newUrl = location.origin + location.pathname + '?' + search + location.hash;
    const state = { titLe: '', url: window.location.href };
    history.replaceState(state, '', newUrl);
    AppManager.getInstance().updateUrl(routeObj);
}

standard();
