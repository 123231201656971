<template>
    <the-form-layout :init-method="getSmsConfig" :sure-method="updateSmsConfig">
        <template #default="{ data: smsData }">
            <isc-form-item label="短信源">
                <isc-tabs v-model="smsData.defaultChannel" type="card">
                    <!-- 阿里云 -->
                    <isc-tab-pane label="阿里云" name="aliyun">
                        <ali-cloud ref="aliCloud" :sms-data="smsData.aliSms" />
                    </isc-tab-pane>
                    <isc-tab-pane label="亿美短信" name="emay">
                        <Emay :sms-data="smsData.emaySms" :template-data="smsData.smsTemplateData" />
                    </isc-tab-pane>
                    <isc-tab-pane label="短信猫" name="smscat">
                        <gsm :sms-data="smsData.hardwareSms" :template-data="smsData.smsTemplateData" />
                    </isc-tab-pane>
                </isc-tabs>
            </isc-form-item>
        </template>
    </the-form-layout>
</template>

<script>
import TheFormLayout from '../components/the-form-layout';
import AliCloud from './AliCloud';
import Emay from './Emay';
import gsm from './GSMSMModem';
import { getSmsConfig, updateSmsConfig } from './service';
export default {
    name: 'SmsConfig',

    components: {
        TheFormLayout,
        AliCloud,
        Emay,
        gsm
    },

    data() {
        return {
            getSmsConfig,
            updateSmsConfig,
            activeName: ''
        };
    }
};
</script>

<style lang="scss" scoped></style>
