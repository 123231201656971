<template>
    <the-form-layout :init-method="queryLocationConfig" :sure-method="updateLocationConfig">
        <template #default="{ data: form }">
            <the-form-section title="高德地图">
                <isc-form-item class="w400" label="城市查询 Url">
                    <isc-input class="w364" v-model="form.url" disabled></isc-input>
                </isc-form-item>
                <isc-form-item class="w400" label="Web 服务 AppKey">
                    <isc-input class="w364" v-model="form.webAppKey"></isc-input>
                </isc-form-item>
                <isc-form-item class="w400" label="Web 端 AppKey">
                    <isc-input class="w364" v-model="form.jsAppKey"></isc-input>
                </isc-form-item>
            </the-form-section>
        </template>
    </the-form-layout>
</template>

<script>
import TheFormLayout from '../components/the-form-layout';
import TheFormSection from '../components/the-form-section';
import { queryLocationConfig, updateLocationConfig } from './service';

export default {
    name: 'LbsConfig',

    data() {
        return {
            queryLocationConfig,
            updateLocationConfig
        };
    },

    components: {
        TheFormSection,
        TheFormLayout
    }
};
</script>

<style lang="scss" scoped>
.w400 {
    width: 400px;
}
</style>
