import axios from '../utils/axios';
import store from '@/store/index.js';
import AppManager from '../utils/app-manager';

export const queryWeather = async () => {
    return axios({
        url: '/api/common/weather/service/city'
    });
};

export const queryRental = async () => {
    return axios('/api/sec/permission/ldapUser/query/user/rental');
};

export async function getUserInfo() {
    const data = await axios.get('/api/permission/auth/status');
    const appInfoList = await axios.get('/api/ops/config/user/desktop/getMenuList');
    const { uuid, type, status, ticket, deptName, userName, telePhone } = data;
    const { state } = store;
    state['sso'].appInfoList = appInfoList;
    state.app.user = {
        ...state.app.user,
        appList: appInfoList.filter((item) => item.inMenu === 1)
    };
    state.app.commonConfig = true; // 现在inMenu不等于1的菜单，菜单列表不会返回，而且公共服务是基础服务也不会关掉，所以可以直接显示
    state.app.sysManage = appInfoList.findIndex((item) => item.code === 'sysmanage') !== -1;

    let appManager = AppManager.getInstance();
    appManager.setUserAppList(store.state.app.user.appList);
    return data;
}

export const getDeskList = async () => {
    return await axios.get('/api/ops/config/user/desktop/getUserConfig');
};

export const updateDeskList = async (params) => {
    return await axios.post('/api/ops/config/user/desktop/insertOrUpdate', params);
};

export const changePwd = async (oldPassword, password) => {
    const params = { oldPassword, password };
    return await axios.put('/api/permission/user/changePwd', params);
};

//浮窗通知列表
export const showMessage = async (params) => {
    return await axios.post('/api/function/message/source/showMessage', params);
};

//判读异常页签显示
export const isShowException = async () => {
    return await axios.get('/api/function/message/source/isShowException');
};

//来源应用列表
export const messageSourceAppList = async () => {
    return await axios.get('/api/function/message/source/messageSourceAppList');
};

// 通知级别列表
export const messageLevelList = async () => {
    return await axios.get('/api/function/message/source/messageLevelList');
};

//分页查询通知
export const pageMessageList = async (params) => {
    return await axios.post('/api/function/message/source/pageMessageList', params);
};

//获取未读通知条数
export const unReadCount = async () => {
    return await axios.get(`/api/function/message/source/unReadCount`);
};

// 查看通知详情
export const messageDtl = async (id) => {
    return await axios.get(`/api/function/message/source/viewMessage/${id}`);
};

//通知详情浏览自动已读标记
export const handleMessage = async (params) => {
    return await axios.post('/api/function/message/status/handleMessage', params);
};

//批量标记已读
export const readMessageByMessageUniqueKeys = async (params) => {
    return await axios.post(`/api/function/message/status/readMessageByMessageUniqueKeys`, params);
};

//批量删除通知
export const deleteMessageByIds = async (params) => {
    return await axios.delete('/api/function/message/status/deleteMessageByIds', { data: params });
};
