<template>
    <isc-dialog
        class="common-settings-wrapper"
        ref="dialog"
        :visible.sync="visible"
        width="1100px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        append-to-body
    >
        <isc-container class="page">
            <nav-menu v-model="type" />
            <component :is="type"></component>
        </isc-container>
    </isc-dialog>
</template>
<script>
import NavMenu from './components/NavMenu';
import BasicConfig from '@/views/common-settings/basic-config';
import EmailConfig from '@/views/common-settings/email-config';
import LbsConfig from '@/views/common-settings/lbs-config';
import MsgConfig from '@/views/common-settings/msg-config';
import SmsConfig from '@/views/common-settings/sms-config';
import WeatherConfig from '@/views/common-settings/weather-config';

const defaultType = 'BasicConfig';
export default {
    name: 'CommonSettings',
    components: {
        WeatherConfig,
        SmsConfig,
        MsgConfig,
        LbsConfig,
        EmailConfig,
        BasicConfig,
        NavMenu
    },

    data() {
        return {
            type: defaultType,
            visible: false
        };
    },

    methods: {
        open(type = defaultType) {
            this.type = type;
            this.visible = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.common-settings-wrapper {
    .page {
        height: 800px;
        box-shadow: 0px 2px 4px 0px rgba(230, 180, 180, 0.2);
        border-radius: 2px;
    }

    ::v-deep {
        .isc-dialog {
            padding: 0;

            &__body {
                padding: 0;
            }
        }
    }
}
</style>
