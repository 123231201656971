<template>
    <the-form-layout :init-method="getEmailConfig" :sure-method="handleSure">
        <template #default="{ data: form, sure }">
            <isc-form-item class="w400" label="邮箱账号">
                <isc-input v-model="form.username" placeholder="请输入正确的邮箱地址"></isc-input>
            </isc-form-item>

            <isc-form-item class="w400" label="邮箱密码">
                <isc-input v-model="form.password" placeholder="示例: 123456"></isc-input>
            </isc-form-item>

            <isc-form-item label="邮箱协议">
                <isc-radio v-model="form.protocol" label="smtp">smtp</isc-radio>
                <isc-radio v-model="form.protocol" label="smtps">smtps</isc-radio>
                <isc-radio v-model="form.protocol" label="pop3">pop3</isc-radio>
                <isc-radio v-model="form.protocol" label="imap4">imap4</isc-radio>
            </isc-form-item>

            <isc-form-item class="w400" label="邮箱服务器">
                <isc-input v-model="form.host" placeholder="示例：xxxx.exmail.qq.com"></isc-input>
            </isc-form-item>

            <isc-form-item>
                <isc-button type="plain" icon="isc-icon-setting" :disabled="testDisabled(form)" @click="onTest(form)">
                    测试
                </isc-button>
            </isc-form-item>

            <isc-form-item class="w400" label="签名模板">
                <isc-input resize="none" v-model="form.signKey" placeholder="请输入签名" type="textarea"></isc-input>
            </isc-form-item>

            <isc-form-item label="邮件模板">
                <isc-button type="text" icon="isc-icon-plus" @click="onAdd(form)">新增模板</isc-button>
                <isc-table class="template-table" :data="form.mailTemplate" style="width: 710px" stripe>
                    <isc-table-column prop="name" label="模板名称"> </isc-table-column>
                    <isc-table-column prop="key" label="Key"> </isc-table-column>
                    <isc-table-column prop="operation" label="操作" width="200">
                        <template slot-scope="scope">
                            <isc-button type="text" @click="preview(scope.row)">预览</isc-button>
                            <span class="mx-2" style="opacity: 0.8; width: 1px; margin: 12px">|</span>
                            <isc-button
                                type="text"
                                @click="onEdit(scope.$index, scope.row)"
                                :disabled="scope.row.isInside === 'true'"
                                >编辑</isc-button
                            >
                            <span class="mx-2" style="opacity: 0.8; width: 1px; margin: 12px">|</span>
                            <isc-button
                                class="delete-btn"
                                type="text"
                                @click="onDelete(scope.$index, scope.row, form, sure)"
                                :disabled="scope.row.isInside === 'true'"
                            >
                                删除
                            </isc-button>
                        </template>
                    </isc-table-column>
                </isc-table>
            </isc-form-item>

            <the-email-dialog
                ref="dialogEmail"
                :form-prop="formProp"
                :title="title"
                @onsure="(val) => onSure(val, form, sure)"
            />
            <the-preview-dialog ref="previewDialog"></the-preview-dialog>
        </template>
    </the-form-layout>
</template>

<script>
import TheFormLayout from '../components/the-form-layout';
import TheEmailDialog from './components/the-email-dialog';
import ThePreviewDialog from './components/the-preview-dialog';
import { getEmailConfig, updateEmailConfig, testEmail } from './service';

export default {
    name: 'EmailConfig',

    components: {
        TheFormLayout,
        ThePreviewDialog,
        TheEmailDialog
    },

    data() {
        return {
            getEmailConfig,
            updateEmailConfig,

            formProp: { name: '', key: '', value: '' },
            title: '',
            index: ''
        };
    },

    methods: {
        testDisabled(form) {
            return !(form.username && form.password && form.protocol && form.host);
        },

        async onTest(form) {
            const reg = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
            if (!reg.test(form.username)) {
                this.$message.error('邮箱格式不正确');
                return;
            }
            try {
                await testEmail(form);
                this.$message.success('测试连接成功');
            } catch (error) {
                this.$message.error('测试连接失败');
            }
        },

        preview(item) {
            this.$refs.previewDialog.open(item.value);
        },

        async onDelete(index, item, form, sure) {
            try {
                await this.$confirm('删除后，当前邮件将无法发送，确认要删除该邮件模板吗？', '删除邮件模板', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确认',
                    cancelButtonText: '取消'
                });
            } catch (err) {
                return;
            }

            form.mailTemplate.splice(index, 1);
            sure();
        },

        onEdit(index, item) {
            this.title = '编辑邮件模板';
            this.formProp = item;
            this.index = index;
            this.$refs.dialogEmail.dialogVisible = true;
        },

        onAdd(form) {
            this.title = '新增邮件模板';
            this.formProp = { name: '', key: '', value: '' };
            this.index = form.mailTemplate.length;
            this.$refs.dialogEmail.dialogVisible = true;
        },

        async onSure(val, form) {
            form.mailTemplate.splice(this.index, 1);
            form.mailTemplate.splice(this.index, 0, val);
        },
        async handleSure(form) {
            const reg = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
            if (!reg.test(form.username)) {
                this.$message.error('邮箱格式不正确');
                return false;
            }
            try {
                await this.updateEmailConfig(form);
            } catch (error) {
                this.$message.error(error);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.template-table {
    color: #333333;
    outline: 1px solid rgba(0, 0, 0, 0.15);
    overflow: visible;
    &::before {
        height: 0;
    }
    .isc-table__header {
        &-wrapper {
            overflow: visible;
        }
    }
    ::v-deep .isc-table__body {
        &-wrapper {
            overflow: visible;
        }
        td .cell {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .delete-btn {
        color: #fe694a;
        &.is-disabled {
            color: rgba(0, 0, 0, 0.25);
        }
    }
}
</style>
