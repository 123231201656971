<template>
    <isc-form
        class="the-form-layout flex-grow-1 d-flex flex-column"
        label-position="left"
        :label-width="labelWidth"
        :model="initData"
        :rules="rules"
    >
        <main class="the-form-layout__main d-flex flex-grow-1">
            <isc-scrollbar class="the-form-layout__content">
                <p class="color-warning" v-if="initLoading">加载中...</p>
                <p class="color-danger" v-else-if="initError">{{ initError.message }}</p>
                <slot v-else-if="initReady" v-bind="{ data: initData, sure }"></slot>
            </isc-scrollbar>
        </main>

        <footer class="the-form-layout__footer flex-shrink-0 d-flex align-center" v-if="footer">
            <isc-form-item>
                <isc-button
                    type="primary"
                    :loading="initLoading || sureLoading"
                    :disabled="initLoading || sureLoading"
                    @click="onSure"
                >
                    保存
                </isc-button>
            </isc-form-item>
        </footer>
    </isc-form>
</template>

<script>
import { error, success } from '../../../utils/message';

export default {
    name: 'TheFormLayout',

    props: {
        footer: {
            type: Boolean,
            default: true
        },

        labelWidth: {
            type: String,
            default: '110px'
        },

        rules: {
            type: Object,
            default: () => ({})
        },

        initMethod: {
            type: Function,
            default: async () => {}
        },

        sureMethod: {
            type: Function,
            default: async (form) => {}
        }
    },

    data() {
        return {
            initLoading: false,
            initError: null,
            initData: null,
            initReady: false,

            sureLoading: false
        };
    },

    created() {
        this.init();
    },

    methods: {
        async init() {
            try {
                this.initLoading = true;
                this.initData = await this.initMethod();
                this.initReady = true;
            } catch (err) {
                this.initError = err;
                error(err);
            } finally {
                this.initLoading = false;
            }
        },

        reset() {
            this.initData = null;
            this.initError = null;
            this.initReady = false;
            this.init();
        },

        sure() {
            this.submit();
        },

        async submit() {
            try {
                this.sureLoading = true;
                const flag = await this.sureMethod(this.initData);
                if (flag === false) return;
                success('保存成功');
            } catch (err) {
                console.dir(err);
                debugger;
                return error(err);
            } finally {
                this.sureLoading = false;
            }
        },

        onSure() {
            this.submit();
        }
    }
};
</script>

<style scoped lang="scss">
.the-form-layout {
    &__main {
        position: relative;
    }

    &__content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        ::v-deep {
            .isc-scrollbar__view {
                padding: 20px;
            }
        }
    }

    &__footer {
        height: 64px;
        border-top: 1px solid #ddd;

        ::v-deep {
            .isc-form-item {
                flex-grow: 1;
                margin-bottom: 0;
            }
        }
    }
}
</style>
