<template>
    <div>
        <!-- 通知抽屉 -->
        <isc-drawer
            title=""
            :visible.sync="drawer"
            direction="rtl"
            :modal="false"
            :modal-append-to-body="false"
            :append-to-body="false"
            :with-header="false"
            size="306px"
            custom-class="drawer"
        >
            <div class="header">
                <span v-if="scrolled < 100">未读通知({{ noReadNum > 99 ? '99+' : noReadNum }})</span>
                <isc-radio-group
                    v-else
                    v-model="radio"
                    @change="getList()"
                    size="small"
                    :border="false"
                    text-color="#333"
                    fill="#fff"
                    class="header-tab"
                >
                    <isc-radio-button label="通知">
                        <!-- <isc-badge :value="noReadTypeNum.notify" :max="99" :hidden="noReadTypeNum.notify === 0">通知</isc-badge> -->
                        <isc-badge is-dot :hidden="noReadTypeNum.notify === 0">通知</isc-badge>
                    </isc-radio-button>
                    <isc-radio-button label="告警">
                        <!-- <isc-badge :value="noReadTypeNum.alert" :max="99" :hidden="noReadTypeNum.alert === 0">告警</isc-badge> -->
                        <isc-badge is-dot :hidden="noReadTypeNum.alert === 0">告警</isc-badge>
                    </isc-radio-button>
                    <isc-radio-button v-if="isShow" label="异常">
                        <!-- <isc-badge :value="noReadTypeNum.exception" :max="99" :hidden="noReadTypeNum.exception === 0">异常</isc-badge> -->
                        <isc-badge is-dot :hidden="noReadTypeNum.exception === 0">异常</isc-badge>
                    </isc-radio-button>
                </isc-radio-group>
                <isc-svg-icon name="iscon-bold-close" size="20px" @click="drawer = false"></isc-svg-icon>
            </div>
            <div class="body" ref="bodyContent" @mousewheel="scrool">
                <div class="body-tab">
                    <isc-radio-group
                        v-model="radio"
                        @change="getList()"
                        size="small"
                        :border="false"
                        text-color="#333"
                        fill="#fff"
                    >
                        <isc-radio-button label="通知">
                            <!-- <isc-badge :value="noReadTypeNum.notify" :max="99" :hidden="noReadTypeNum.notify === 0">通知</isc-badge> -->
                            <isc-badge is-dot :hidden="noReadTypeNum.notify === 0">通知</isc-badge>
                        </isc-radio-button>
                        <isc-radio-button label="告警">
                            <!-- <isc-badge :value="noReadTypeNum.alert" :max="99" :hidden="noReadTypeNum.alert === 0">告警</isc-badge> -->
                            <isc-badge is-dot :hidden="noReadTypeNum.alert === 0">告警</isc-badge>
                        </isc-radio-button>
                        <isc-radio-button v-if="isShow" label="异常">
                            <!-- <isc-badge :value="noReadTypeNum.exception" :max="99" :hidden="noReadTypeNum.exception === 0">异常</isc-badge> -->
                            <isc-badge is-dot :hidden="noReadTypeNum.exception === 0">异常</isc-badge>
                        </isc-radio-button>
                    </isc-radio-group>
                </div>
                <div v-for="(item, index) in msgList" :key="'app' + index">
                    <p class="body-title">
                        <span>{{ item.messageAppName }}({{ item.total > 99 ? '99+' : item.total }})</span>
                        <span v-if="item.total > 5" @click="isShowMsg=true" class="body-title__right">
                            更多 <isc-svg-icon name="iscon-angle-right" size="12px"></isc-svg-icon>
                        </span>
                    </p>
                    <template v-for="(child, i) in item.messageInfo">
                        <div
                            v-if="i < 5"
                            :key="item.messageAppName + i"
                            @click="$refs.coreMsgDtl.initMethod(child.messageUniqueKey)"
                            :class="{ 'body-item': true, active: item.total < 5 }"
                        >
                            <div class="body-item-top">
                                <p class="body-item-top__name">{{ child.messageTitle }}</p>
                                <p class="body-item-top__desc">{{ child.messageContent }}</p>
                            </div>
                            <p class="body-item__name">{{ child.messageTitle }}</p>
                            <p class="body-item__desc">{{ child.messageContent }}</p>
                            <!-- 警告、通知、严重 -->
                            <span
                                :class="{
                                    'body-item__type': true,
                                    info: child.messageLevel === 1,
                                    warning: child.messageLevel === 2,
                                    danger: child.messageLevel === 3
                                }"
                                >{{
                                    child.messageLevel === 1
                                        ? '提示'
                                        : child.messageLevel === 2
                                        ? '重要'
                                        : child.messageLevel === 3
                                        ? '紧急'
                                        : ''
                                }}</span
                            >
                            <span class="body-item__time">{{ formatTime(child.sendTime) }}</span>
                        </div>
                    </template>
                </div>
                <div v-if="msgList.length === 0" class="body-none">当前没有最新通知！</div>
            </div>
            <div class="footer" @click="isShowMsg=true">
                <p class="footer-left">
                    <isc-svg-icon class="footer-left__icon" name="iscon-xiaoxizhongxin" size="18px"></isc-svg-icon>
                    <span>通知中心</span>
                </p>
                <i class="isc-icon-arrow-right"></i>
            </div>
        </isc-drawer>
        <!-- 通知中心 -->
        <core-msgcenter v-if="isShowMsg" ref="coreMsgCenter" @lookdtl="openDtl" @return="returnFun" @closewin="isShowMsg=false"></core-msgcenter>
        <!-- 通知详情 -->
        <core-msgdtl ref="coreMsgDtl" @return="returnFun"></core-msgdtl>
    </div>
</template>

<script>
import coreMsgcenter from './core-msgcenter.vue';
import coreMsgdtl from './core-msgdtl.vue';
import { error, success } from '@/utils/message';
import { isShowException, unReadCount, showMessage } from '@/service/common.js';
export default {
    name: 'CoreDrawer',
    data: () => ({
        drawer: false,
        isShowMsg: false,
        noReadNum: null,
        radio: '通知',
        msgList: [],
        scrolled: 0,
        isShow: false,
        noReadTypeNum: {
            all: 0,
            notify: 0,
            alert: 0,
            exception: 0
        }
    }),
    components: { coreMsgcenter, coreMsgdtl },
    beforeDestroy() {
        this.$refs.bodyContent.removeEventListener('scroll', this.scrool);
    },
    computed: {},

    methods: {
        openDtl(id) {
            this.$refs.coreMsgDtl.initMethod(id);
        },
        returnFun() {
            this.getList();
            // this.queryMsg();
            this.$refs.coreMsgCenter.handleClick();
        },
        scrool() {
            this.scrolled = this.$refs.bodyContent.scrollTop;
        },
        async open(num) {
            this.drawer = true;
            this.showException();
            let data;
            try {
                data = await showMessage({
                    messageType:
                        this.radio === '通知' ? 1 : this.radio === '告警' ? 2 : this.radio === '异常' ? 3 : null
                });
            } catch (err) {
                console.log(err);
                return;
            }
            this.msgList = data;
            // this.noReadNum = num;
            this.queryMsg();
            this.$nextTick(() => {
                this.$refs.bodyContent.addEventListener('scroll', this.scrool);
            });
        },
        async getList() {
            this.msgList = [];
            let data;
            try {
                data = await showMessage({
                    messageType:
                        this.radio === '通知' ? 1 : this.radio === '告警' ? 2 : this.radio === '异常' ? 3 : null
                });
            } catch (err) {
                console.log(err);
                return;
            }
            this.msgList = data;
            this.queryMsg();
        },
        async showException() {
            let data;
            try {
                data = await isShowException();
            } catch (err) {
                console.log(err);
                return;
            }
            this.isShow = data;
        },
        async queryMsg() {
            let count;
            try {
                count = await unReadCount();
            } catch (err) {
                console.log(err);
                return;
            }
            this.noReadNum = count.all;
            this.noReadTypeNum = count;
            this.$emit('reloadnum', count);
        },
        formatTime(time) {
            return time.substring(0, 19).replace('T', ' ');
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .isc-drawer {
    top: 60px !important;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: saturate(180%) blur(6px);
    border-left: 1px solid #ccc;
}
::v-deep .isc-radio-group {
    border: 1px solid #ddd;
    .isc-radio-button__inner {
        width: 68px;
        background: #ddd;
        border: none;
    }
}
.header {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    border-bottom: 1px solid #ccc;
    background: #828188;
    &-tab {
        position: relative;
        left: 50%;
        margin-left: -102px;
    }
}
.body {
    height: 100vh;
    padding: 60px 16px 100px;
    overflow: auto;
    &-tab {
        text-align: center;
    }
    &-title {
        font-size: 16px;
        color: #333;
        margin: 24px 0 10px;
        &__right {
            font-size: 12px;
            float: right;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-top: 2px;
        }
        &:first-of-type {
            margin-top: 10px;
        }
    }
    &-item {
        height: 46px;
        padding: 16px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 2px;
        border: 1px solid #bbbbbb;
        cursor: pointer;
        font-size: 12px;
        overflow: hidden;
        margin-bottom: 8px;
        transition: all 0.2s linear;
        -moz-transition: all 0.2s linear; /* Firefox 4 */
        -webkit-transition: all 0.2s linear; /* Safari and Chrome */
        -o-transition: all 0.2s linear; /* Opera */
        &-top {
            display: flex;
            &__name {
                width: 98px;
                flex: 0 0 98px;
                font-weight: 500;
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &__desc {
                flex: 1;
                color: #999999;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        &__name {
            display: none;
            font-weight: 500;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 8px;
        }
        &__desc {
            display: none;
            height: 36px;
            line-height: 18px;
            color: #999999;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-bottom: 8px;
        }
        &__type {
            display: none;
            float: left;
            color: #ffffff;
            padding: 4px 8px;
            border-radius: 10px;
        }
        .info {
            background: #999999;
        }
        .warning {
            background: #ffba00;
        }
        .danger {
            background: #fe694a;
        }
        &__time {
            display: none;
            float: right;
            color: #666666;
            line-height: 20px;
        }
        &:hover {
            height: 118px;
        }
        &:hover .body-item-top {
            display: none;
        }
        &:hover .body-item__name {
            display: block;
        }
        &:hover .body-item__desc {
            display: -webkit-box;
        }
        &:hover .body-item__type {
            display: block;
        }
        &:hover .body-item__time {
            display: block;
        }
        &.active {
            height: 118px;
        }
        &.active .body-item-top {
            display: none;
        }
        &.active .body-item__name {
            display: block;
        }
        &.active .body-item__desc {
            display: -webkit-box;
        }
        &.active .body-item__type {
            display: block;
        }
        &.active .body-item__time {
            display: block;
        }
    }
    &-none {
        position: absolute;
        top: 45%;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 14px;
        color: #333;
    }
}
.footer {
    position: absolute;
    bottom: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    color: #fff;
    background: #828188;
    cursor: pointer;
    &-left {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        &__icon {
            margin-right: 8px;
        }
    }
}
// ::v-deep .isc-badge__content.is-fixed {
//     top: -10px;
//     right: 5px;
// }
</style>
