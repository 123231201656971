import Vue from 'vue';
import '@isyscore/styles';
import App from './App.vue';

import router from './router';
import store from './store';
import './plugins/messenger';
import './plugins/storageListener';
import './plugins/base-ui';
import './plugins/admin-ui';
import '@/plugins/svg-icon';
import './plugins/global-inject';
import AppManager from './utils/app-manager';

Vue.prototype.$appManager = AppManager.getInstance();
Vue.config.productionTip = false;
import pkg from '../package.json';

window.__VERSION__ = pkg.version;

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app');
