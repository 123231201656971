/*
 * @Author: your name
 * @Date: 2020-09-08 14:06:26
 * @LastEditTime: 2020-09-08 15:49:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /isc-desktop-ui/src/plugins/storageListener.js
 */
'use strict';

import Vue from 'vue';

const restSetItem = function (key, newVal) {
    if (key === 'bgImg') {
        let newStorageEvent = document.createEvent('StorageEvent');
        const storage = {
            setItem: function (k, val) {
                window.localStorage.setItem(k, val);

                // 初始化创建的事件
                newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

                // 派发对象
                window.dispatchEvent(newStorageEvent);
            }
        };
        return storage.setItem(key, newVal);
    }
};

Vue.prototype.restSetItem = restSetItem;
