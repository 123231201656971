<template>
    <the-form-layout :init-method="getBasicConfig" :sure-method="updateBasicConfig">
        <template #default="{ data: form }">
            <isc-form-item class="w400" label="企业名称">
                <isc-input
                    :disabled="!canEdit"
                    v-model="form.enterprise"
                    @input="updateCompanyName"
                    placeholder="请输入企业名称"
                ></isc-input>
            </isc-form-item>
            <isc-form-item class="w400" label="企业联络电话">
                <isc-input :disabled="!canEdit" v-model="form.phoneNum" placeholder="请输入企业联络电话"></isc-input>
            </isc-form-item>
            <isc-form-item class="w400" label="企业描述">
                <isc-input :disabled="!canEdit" v-model="form.powerby" placeholder="请输入企业描述"></isc-input>
            </isc-form-item>
            <isc-form-item label="Logo">
                <the-upload-img
                    :disabled="!canEdit"
                    :thumbnail="form.logo"
                    @change-image="(url) => (form.logo = url)"
                    @remove="removeLogo(form)"
                ></the-upload-img>
            </isc-form-item>
        </template>
    </the-form-layout>
</template>

<script>
import TheFormLayout from '../components/the-form-layout';
import TheUploadImg from './components/the-upload-img';
import { getBasicConfig, updateBasicConfig, queryCanEdit } from './service';

export default {
    name: 'BasicConfig',
    data() {
        return {
            getBasicConfig,
            updateBasicConfig,
            canEdit: false
        };
    },

    components: {
        TheFormLayout,
        TheUploadImg
    },
    created() {
        this.setCanEdit();
    },
    methods: {
        async setCanEdit() {
            try {
                // 传参ids:[0,1] 指标0:能否修改logo
                const res = await queryCanEdit([0]);
                this.canEdit = res[0];
            } catch (err) {
                console.error(err);
                this.canEdit = false;
            }
        },
        removeLogo(form) {
            form.logo = '';
        },

        updateCompanyName(val) {
            this.$store.commit('app/setUser', {
                ...this.$store.state.app.user,
                companyDetail: {
                    ...this.$store.state.app.user.companyDetail,
                    enterprise: val || ''
                }
            });
        },

        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },

        changeFile(form, file) {
            form.logo = file;
        }
    }
};
</script>

<style lang="scss" scoped>
.w400 {
    width: 400px;
}

.basic-config-wrapper {
    .isc-form {
        width: 400px;
        &-item {
            ::v-deep .isc-form-item__label {
                color: #333333 !important;
            }
        }

        .avatar-uploader {
            width: 64px;
            height: 64px;
            ::v-deep .isc-upload {
                height: 64px;
                width: 64px;
                line-height: 64px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
            }
        }
        .avatar-uploader .isc-upload:hover {
            border-color: #409eff;
        }
        .avatar-uploader-icon {
            vertical-align: middle;
        }
        .avatar {
            width: 64px;
            height: 64px;
            vertical-align: middle;
        }
    }
}
</style>
