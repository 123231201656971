<template>
    <div class="upload-img">
        <div
            class="img-item"
            :class="{
                'active-hover':
                    (!uploadParams.status || uploadParams.status === 'success' || uploadParams.status === 'ready') &&
                    uploadParams.thumbnail
            }"
        >
            <isc-image v-if="uploadParams.thumbnail" class="img-item__thumbnail" :src="thumbnailUrl"></isc-image>
            <isc-image v-else class="img-item__default" :src="defaultImg"></isc-image>
            <div class="img-item__mask half-mask" v-if="uploadParams.status === 'progress'">
                <span>{{ uploadParams.percentage }}%</span>
            </div>
            <div class="img-item__mask show-mask delay-hidden" v-if="uploadParams.stathumbnailUrltus === 'success'">
                <img class="status-icon" src="../../assets/icon-success.png" alt="" srcset="" />
                <span class="status-lable">上传成功</span>
            </div>
            <div class="img-item__mask show-mask" v-if="uploadParams.status === 'error'">
                <img class="status-icon" src="../../assets/icon-failed.png" alt="" srcset="" />
                <span class="status-lable">上传失败</span>
                <a class="retry-upload" @click="reUpload">重试</a>
            </div>
            <div class="img-item__mask img-item__btn">
                <i class="isc-icon-circle-close" @click="handleRemove()"></i>
            </div>
        </div>
        <span class="img-item__tip">图片尺寸不小于24x24像素，不超过500K，支持SVG/PNG/JPG格式</span>
        <isc-upload
            class="img-upload"
            ref="upload"
            name="file"
            :data="{ type: 'logo' }"
            :headers="{ token: $store.state.app.user.token, 'isc-api-version': '2.0' }"
            :before-upload="beforeUpload"
            :file-queued="handleFileQueued"
            :on-start="handleStart"
            :on-progress="handleProgress"
            :on-exceed="handlExceed"
            :on-success="handleSuccess"
            :on-error="handleError"
            action="/api/ops/config/common/upload"
            accept=".svg,.png,.jpg"
            :disabled="disabled"
        >
            <isc-link :underline="false" type="primary">{{ linkLabel }}</isc-link>
        </isc-upload>
    </div>
</template>
<script>
import logo from '../../assets/icon.png';

export default {
    name: 'TheUploadImg',
    data() {
        return {
            status: 'start',
            percentage: '',
            uploadParams: {
                thumbnail: '',
                status: '',
                percentage: 0
            },
            uploadFile: {},
            defaultImg: logo
        };
    },
    props: {
        thumbnail: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: true
        }
    },

    created() {
        this.defaultImg = this.thumbnail;
    },

    computed: {
        thumbnailUrl() {
            const { thumbnail } = this.uploadParams;
            if (process.env.NODE_ENV === 'development') return ENVS.desktopUrl.replace(/\/$/, '') + thumbnail;
            return thumbnail;
        },
        linkLabel() {
            return this.disabled ? '' : this.uploadParams.status === '' ? '上传图片' : '重新上传';
        }
    },

    methods: {
        reUpload() {
            this.$refs.upload.uploadFiles = [this.uploadFile];
            this.$refs.upload.upload(this.uploadFile);
        },
        beforeUpload(file) {
            const isIMG = ['svg', 'png', 'jpg'].includes(this.tolowerCase(file.name).split('.').pop());
            const isLimit = file.size / 1024 / 1024 < 20;

            if (!isIMG) {
                this.$message.error('上传图片只支持SVG/PNG/JPG格式格式!'); //图片尺寸不小于24x24像素，不超过500K，支持SVG/PNG/JPG格式
            }
            if (!isLimit) {
                this.$message.error('上传图片大小不能超过 20MB!');
            }
            return isIMG && isLimit;
        },
        handleFileQueued(file) {
            this.uploadFile = file;
        },
        handleStart(file) {
            this.uploadParams.thumbnail = file.url;
            this.uploadParams.percentage = 0;
            this.uploadParams.status = 'ready';
            this.$emit('upload-status', true);
        },
        handleProgress(file) {
            this.uploadParams.percentage = parseInt(file.percent, 10);
            this.uploadParams.status = 'progress';
        },
        handlExceed() {
            this.$message.error('文件个数不能超出3个');
        },
        handleSuccess(res) {
            this.uploadParams.percentage = 100;
            this.uploadParams.thumbnail = res.data;
            this.uploadParams.status = 'success';
            this.$message.success('上传成功');
            this.$emit('change-image', res.data);
            this.$emit('upload-status', false);
        },
        handleError(err) {
            const { message } = JSON.parse(err.message);
            this.$message.error(message);
            this.uploadParams.status = 'error';
            this.$emit('upload-status', false);
        },
        handleRemove() {
            this.$refs.upload.handleRemove(this.file);
            this.uploadParams.status = '';
            this.uploadParams.thumbnailUrl = '';
            this.$emit('remove');
        },
        tolowerCase(filePath) {
            let startIndex = filePath.lastIndexOf('.');
            if (startIndex !== -1) {
                return filePath.substring(startIndex + 1, filePath.length).toLowerCase();
            }
            return '';
        }
    },
    watch: {
        thumbnail(val) {
            this.$set(this.uploadParams, 'thumbnail', val);
            if (val) {
                this.uploadParams.status = 'ready';
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@keyframes pop-onetime {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.upload-img {
    display: flex;
}

.img-item {
    width: 64px;
    height: 64px;
    background-image: url(../../assets/logo-background.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;

    &__mask {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba($color: #000000, $alpha: 0.45);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #fff;
    }

    &__thumbnail {
        width: 100%;
        height: 100%;
    }

    &__default {
        width: 44px;
        height: 38px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__btn {
        display: none;

        i {
            position: absolute;
            right: -4px;
            top: -4px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            color: #fe694a;
            background: #fff;
            padding-top: 1px;
            cursor: pointer;
        }
    }

    &__tip {
        position: absolute;
        left: 72px;
        top: 50%;
        transform: translateY(-50%);
        white-space: nowrap;
        color: #bbb;
    }

    .delay-hidden {
        animation: pop-onetime 0.1s linear 1s 1 normal running forwards;
    }
}

.active-hover:hover {
    .img-item__btn {
        display: block;
    }
}

.img-upload {
    line-height: 12px;
    margin-left: 8px;
}

.status-icon {
    width: 16px;
    height: 16px;
}
.status-lable {
    line-height: 16px;
    color: #fff;
}
.retry-upload {
    color: #fff;
    line-height: 16px;
    cursor: pointer;
    &:hover {
        color: #047aff;
        text-decoration: underline;
    }
}
</style>
