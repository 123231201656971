<template>
    <isc-dialog class="the-preview-dialog" title="短信模板预览" :visible.sync="visible" append-to-body>
        <div class="the-preview-dialog__content">{{ html }}</div>

        <template #footer>
            <isc-button type="primary" @click="visible = false">关闭</isc-button>
        </template>
    </isc-dialog>
</template>

<script>
export default {
    name: 'ThePreviewDialog',

    data() {
        return {
            visible: false,
            height: 300,
            html: ''
        };
    },

    computed: {
        iframeStyle() {
            const { height } = this;

            return {
                height: height + 'px'
            };
        }
    },

    methods: {
        async open(html) {
            this.visible = true;
            this.html = html;
        }
    }
};
</script>

<style scoped lang="scss">
.the-preview-dialog {
    &__content {
        color: #333;
        font-size: 14px;
    }
}
</style>
