<template>
    <dialog-layout ref="listDialog" class="the-list-dialog" width="1005px" title="服务详情" show-close :init-method="initMethod" :before-close="()=>$emit('closewin')">
        <template>
            <div class="left">
                <div :class="{ 'left-item': true, active: activeType === '全部通知' }" @click="queryMsg('全部通知')">
                    全部通知
                </div>
                <div :class="{ 'left-item': true, active: activeType === '未读通知' }" @click="queryMsg('未读通知')">
                    <!-- <isc-badge :value="noReadNum" :max="99" :hidden="noReadNum === 0">未读通知</isc-badge> -->
                    <isc-badge :value="noReadTypeNum.all" :max="99" :hidden="noReadTypeNum.all === 0"
                        >未读通知</isc-badge
                    >
                </div>
                <div :class="{ 'left-item': true, active: activeType === '已读通知' }" @click="queryMsg('已读通知')">
                    已读通知
                </div>
            </div>
            <div class="right">
                <div class="right-top">
                    <p class="right-top__title">{{ activeType }}</p>
                    <isc-tabs v-model="activeName" @tab-click="handleClick" class="right-top__tab">
                        <isc-tab-pane name="通知">
                            <span slot="label">
                                <span>通知</span>
                                <isc-badge
                                    v-show="activeType === '未读通知'"
                                    is-dot
                                    :hidden="noReadTypeNum.notify === 0"
                                ></isc-badge>
                            </span>
                        </isc-tab-pane>
                        <isc-tab-pane name="告警">
                            <span slot="label">
                                <span>告警</span>
                                <isc-badge
                                    v-show="activeType === '未读通知'"
                                    is-dot
                                    :hidden="noReadTypeNum.alert === 0"
                                ></isc-badge>
                            </span>
                        </isc-tab-pane>
                        <isc-tab-pane v-if="isShow" name="异常">
                            <span slot="label">
                                <span>异常</span>
                                <isc-badge
                                    v-show="activeType === '未读通知'"
                                    is-dot
                                    :hidden="noReadTypeNum.exception === 0"
                                ></isc-badge>
                            </span>
                        </isc-tab-pane>
                    </isc-tabs>
                </div>
                <table-layout
                    :loader="getList"
                    ref="tableLayout"
                    @selection-change="selectionChangeHandle"
                    style="height: 620px"
                >
                    <template #operator-left>
                        <template v-if="activeType === '已读通知'">
                            <table-operator-button
                                type="default"
                                plain
                                @click="deleteArr"
                                :disabled="selectLength === 0"
                                >删除</table-operator-button
                            >
                            <table-operator-button type="default" plain @click="deleteAll"
                                >全部删除</table-operator-button
                            >
                        </template>
                        <template v-else>
                            <table-operator-button type="default" plain @click="readArr" :disabled="selectLength === 0"
                                >标记已读</table-operator-button
                            >
                            <table-operator-button type="default" plain @click="readAll"
                                >全部已读</table-operator-button
                            >
                        </template>
                    </template>
                    <template #operator-right>
                        <table-operator-search placeholder="请输入通知内容或标题"></table-operator-search>
                    </template>
                    <template #columns>
                        <table-column type="selection"></table-column>
                        <table-column prop="messageContent" label="通知内容" :width="300">
                            <template #default="{ row }">
                                <div
                                    :class="{ content: true, gury: row.readFlag === 1 }"
                                    @click="onDetail(row.messageUniqueKey)"
                                >
                                    {{ row.messageContent }}
                                </div>
                            </template>
                        </table-column>
                        <table-column v-if="activeName === '告警'" prop="messageLevel" label="级别" :width="80">
                            <template #filter>
                                <table-filter-radio param-key="level" :options="levelList"></table-filter-radio>
                            </template>
                            <template #default="{ row }">{{
                                row.messageLevel === 1
                                    ? '提示'
                                    : row.messageLevel === 2
                                    ? '重要'
                                    : row.messageLevel === 3
                                    ? '紧急'
                                    : ''
                            }}</template>
                        </table-column>
                        <table-column prop="messageAppName" label="来源应用" :width="115">
                            <template #filter>
                                <table-filter-radio param-key="source" :options="appList"> </table-filter-radio>
                            </template>
                        </table-column>
                        <table-column prop="sendTime" label="时间">
                            <template #default="{ row }">{{ formatTime(row.sendTime) }}</template>
                        </table-column>
                    </template>
                </table-layout>
            </div>
        </template>
        <template #footer>
            <div></div>
        </template>
    </dialog-layout>
</template>

<script>
import {
    messageSourceAppList,
    pageMessageList,
    unReadCount,
    readMessageByMessageUniqueKeys,
    deleteMessageByIds,
    isShowException
} from '@/service/common.js';
import { success, confirm } from '@/utils/message';
export default {
    name: 'ListDialog',

    components: {},

    data() {
        return {
            activeType: '全部通知',
            activeName: '通知',
            appList: [],
            levelList: [],
            noReadNum: null,
            noReadTypeNum: {
                all: 0,
                notify: 0,
                alert: 0,
                exception: 0
            },
            isShow: false,
            selectLength: 0
        };
    },
    created() {
        this.$nextTick(()=>{
            this.$refs.listDialog.open();
        });
    },
    methods: {
        selectionChangeHandle(val) {
            this.selectLength = val.length || 0;
        },
        async showException() {
            let data;
            try {
                data = await isShowException();
            } catch (err) {
                console.log(err);
                return;
            }
            this.isShow = data;
        },
        deleteAll() {
            confirm('提示', '确认要删除全部通知吗？', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'info '
            })
                .then(async () => {
                    let data;
                    try {
                        data = await deleteMessageByIds({
                            messageUniqueKeys: [],
                            messageType:
                                this.activeName === '通知'
                                    ? 1
                                    : this.activeName === '告警'
                                    ? 2
                                    : this.activeName === '异常'
                                    ? 3
                                    : null
                        });
                    } catch (err) {
                        console.log(err);
                        return;
                    }
                    if (data) {
                        success('删除成功');
                        this.$refs.tableLayout.refresh();
                    }
                })
                .catch(() => {});
        },
        async deleteArr() {
            let selectArr = this.$refs.tableLayout.selection;
            let data;
            try {
                data = await deleteMessageByIds({
                    messageUniqueKeys: selectArr.map((item) => item.messageUniqueKey)
                });
            } catch (err) {
                console.log(err);
                return;
            }
            if (data) {
                success('删除成功');
                this.$refs.tableLayout.refresh();
            }
        },
        readAll() {
            confirm('提示', '确认要标记全部通知为已读吗？', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'info '
            })
                .then(async () => {
                    let data;
                    try {
                        data = await readMessageByMessageUniqueKeys({
                            messageUniqueKeys: [],
                            messageType:
                                this.activeName === '通知'
                                    ? 1
                                    : this.activeName === '告警'
                                    ? 2
                                    : this.activeName === '异常'
                                    ? 3
                                    : null
                        });
                    } catch (err) {
                        console.log(err);
                        return;
                    }
                    if (data) {
                        success('标记成功');
                        this.$refs.tableLayout.refresh();
                        this.$emit('return');
                    }
                })
                .catch(() => {});
        },
        async readArr() {
            let selectArr = this.$refs.tableLayout.selection;
            let data;
            try {
                data = await readMessageByMessageUniqueKeys({
                    messageUniqueKeys: selectArr.map((item) => item.messageUniqueKey)
                });
            } catch (err) {
                console.log(err);
                return;
            }
            if (data) {
                success('标记成功');
                this.$refs.tableLayout.refresh();
                this.$emit('return');
            }
        },
        formatTime(time) {
            return time.substring(0, 19).replace('T', ' ');
        },
        queryMsg(name) {
            this.activeType = name;
            this.$refs.tableLayout.refresh();
        },
        handleClick() {
            if (this.$refs.tableLayout) {
                this.$refs.tableLayout.refresh();
            }
        },
        async initMethod() {
            this.showException();
            let dataAppList;
            try {
                dataAppList = await messageSourceAppList();
            } catch (err) {
                console.log(err);
                return;
            }
            this.appList = [{ label: '全部', value: null }];
            dataAppList.forEach((item) => {
                this.appList.push({ label: item, value: item });
            });
            // let dataLevelList;
            // try {
            //     dataLevelList = await messageLevelList();
            // } catch (err) {
            //     console.log(err);
            //     return;
            // }
            // this.levelList = dataLevelList;
            this.levelList = [
                { label: '全部', value: null },
                { label: '提示', value: 1 },
                { label: '重要', value: 2 },
                { label: '紧急', value: 3 }
            ];
        },
        async getList(params) {
            let count;
            try {
                count = await unReadCount();
            } catch (err) {
                console.log(err);
                return;
            }
            // this.noReadNum = count.all;
            this.noReadTypeNum = count;
            let data;
            let reqObj = {
                searchParam: params.keywords,
                messageType:
                    this.activeName === '通知'
                        ? 1
                        : this.activeName === '告警'
                        ? 2
                        : this.activeName === '异常'
                        ? 3
                        : 0,
                messageAppName: params.source
            };
            if (this.activeName === '告警') {
                reqObj.messageLevel = params.level;
            }
            if (this.activeType === '已读通知') {
                reqObj.readFlag = 1;
            } else if (this.activeType === '未读通知') {
                reqObj.readFlag = 0;
            }
            try {
                data = await pageMessageList({ pageNo: params.pageNum, pageSize: params.pageSize, param: reqObj });
            } catch (err) {
                console.log(err);
                return;
            }
            return {
                records: data.rows || [],
                total: data.total
            };
        },
        async onDetail(id) {
            this.$emit('lookdtl', id);
        }
    }
};
</script>

<style scoped lang="scss">
.left {
    width: 260px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: #fff;
    border-right: 1px solid #ddd;
    &-item {
        display: flex;
        align-items: center;
        height: 52px;
        padding: 0 48px;
        font-size: 14px;
        color: #333333;
        border-left: 2px solid #fff;
    }
    .active {
        border-left: 2px solid #047aff;
        background: linear-gradient(270deg, rgba(4, 122, 255, 0) 0%, rgba(4, 122, 255, 0.31) 100%);
    }
}
.right {
    padding-top: 30px;
    margin-left: 258px;
    position: relative;
    &-top {
        padding: 24px 24px 0;
        box-sizing: border-box;
        position: absolute;
        top: -64px;
        left: -14px;
        right: -16px;
        background: #fff;
        border-bottom: 1px solid #ddd;
        &__title {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            margin-bottom: 8px;
        }
    }
    .content {
        width: 280px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .gury {
        color: #999;
    }
}
::v-deep .isc-dialog {
    background: #f4f4f4;
    padding: 16px;
    &__body {
        padding-bottom: 0;
    }
}
::v-deep .isc-tabs__header {
    margin: 0;
}
::v-deep .isc-tabs__nav {
    border: none;
}
::v-deep .isc-badge__content.is-fixed {
    top: 6px;
    right: -10px;
}
</style>
