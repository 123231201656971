'use strict';

import axios from '../../../utils/axios';
import { qsStringify } from '@isyscore/utils/qs';

const toJSON = (strData) => {
    const httpRexReg = /(?<!"):(?!")/g;
    const newStr = strData.replace(httpRexReg, '\\u003a');
    const rexReg = /(\$)(\{)([A-Za-z]*)(\})/g;
    const newStrEnd = newStr.replace(rexReg, `\\u0024\\u007b$3\\u007d`);
    return JSON.parse(newStrEnd);
};

export const getSmsConfig = async () => {
    let params = {
        appName: 'common-sms-service'
    };
    const data = await axios.get(`/api/ops/config/common/page/configGet`, { params });
    return {
        aliSms: {
            accessKeyId: data['ali-sms.accessKeyId'],
            accessKeySecret: data['ali-sms.accessKeySecret'],
            defaultConnectTimeout: data['ali-sms.accessKeySecret'],
            defaultReadTimeout: data['ali-sms.defaultReadTimeout'],
            domain: data['ali-sms.domain'],
            product: data['ali-sms.product'],
            signatureData: data['ali-sms.signatureData'],
            templateData: toJSON(data['ali-sms.templateData'])
        },
        emaySms: {
            accessKeyId: data['emay-sms.accessKeyId'],
            accessKeySecret: data['emay-sms.accessKeySecret'],
            algorithm: data['emay-sms.algorithm'],
            domain: data['emay-sms.domain']
        },
        hardwareSms: {
            baudRate: data['hardware-sms.baudRate'],
            indexPorts: data['hardware-sms.indexPorts'],
            internationalNo: data['hardware-sms.internationalNo'],
            prefix: data['hardware-sms.prefix'],
            sendPhone: data['hardware-sms.sendPhone'],
            sendPhonePrefix: data['hardware-sms.sendPhonePrefix'],
            suffix: data['hardware-sms.suffix'],
            tcpHost: data['hardware-sms.tcpHost'],
            tcpPort: data['hardware-sms.tcpPort']
        },
        defaultChannel: data['sms-template.defaultChannel'],
        accessKeyId: data['signature.accessKeyId'],
        accessKeySecret: data['signature.accessKeySecret'],
        smsTemplateData: toJSON(data['sms-template.jsonData']),
        apiAuthsAccessKeyId: data['apiAuths.apiAuthMap.accessKeyId-emhvbmd6aG91']
    };
};

export const updateSmsConfig = async (smsData) => {
    let params = {
        appName: 'common-sms-service',
        map: {
            'ali-sms.accessKeyId': smsData.aliSms.accessKeyId,
            'ali-sms.accessKeySecret': smsData.aliSms.accessKeySecret,
            'ali-sms.templateData': JSON.stringify(smsData.aliSms.templateData),
            'emay-sms.accessKeyId': smsData.emaySms.accessKeyId,
            'emay-sms.accessKeySecret': smsData.emaySms.accessKeySecret,
            'hardware-sms.sendPhone': smsData.hardwareSms.sendPhone,
            'hardware-sms.sendPhonePrefix': smsData.hardwareSms.sendPhonePrefix,
            'hardware-sms.internationalNo': smsData.hardwareSms.internationalNo,
            'hardware-sms.tcpHost': smsData.hardwareSms.tcpHost,
            'hardware-sms.tcpPort': smsData.hardwareSms.tcpPort,
            'sms-template.jsonData': JSON.stringify(smsData.smsTemplateData),
            'sms-template.defaultChannel': smsData.defaultChannel
        }
    };
    return await axios.post(`/api/ops/config/common/page/configUpdate`, params);
};

export const testSms = async (phoneNum, templateId) => {
    const params = {
        phoneNum,
        templateId
    };
    return await axios.post(`/api/ops/config/common/test/sms?${qsStringify(params)}`);
};
