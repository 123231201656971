<template>
    <div class="the-email-dialog">
        <isc-dialog :title="title" :visible.sync="dialogVisible" append-to-body>
            <isc-form :model="form" label-width="100px" label-position="left">
                <isc-form-item label="模板名称">
                    <isc-input v-model="form.name"></isc-input>
                </isc-form-item>
                <isc-form-item label="Key">
                    <isc-input v-model="form.key" :disabled="!isAdd"></isc-input>
                </isc-form-item>
                <isc-form-item label="内容">
                    <isc-input v-model="form.value" type="textarea" rows="3" autosize></isc-input>
                </isc-form-item>
            </isc-form>
            <span slot="footer" class="dialog-footer">
                <isc-button @click="dialogVisible = false">取 消</isc-button>
                <isc-button type="primary" @click="onSure">确 定</isc-button>
            </span>
        </isc-dialog>
    </div>
</template>

<script>
export default {
    name: 'TheSmsDialog',
    props: {
        formProp: {
            type: Object,
            default: () => {
                return {};
            }
        },
        title: {
            type: String,
            default: ''
        },
        isAdd: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        formProp: {
            handler(val) {
                this.form = val;
            },
            deep: true
        }
    },
    data() {
        return {
            form: {
                name: '',
                key: '',
                value: ''
            },
            isClear: false,
            dialogVisible: false
        };
    },
    methods: {
        onSure() {
            this.dialogVisible = false;
            this.$emit('sure', this.form);
        }
    }
};
</script>

<style lang="scss" scoped>
.the-email-dialog {
    ::v-deep {
        .isc-form-item {
            margin-bottom: 24px;
        }
    }
}

.templateMsgBox {
    width: auto;
}
</style>
