'use strict';

import { qsStringify } from '@isyscore/utils/qs';
import axios from '../../../utils/axios';

export const getMsgService = async (appName, key) => {
    let params = {
        appName,
        key
    };
    const data = await axios.get(`/api/ops/config/common/message/getConfig`, { params });
    let formDing = {};
    let formWX = {};

    if (data.dt) {
        formDing = data.dt;
        formDing.corpId = formDing.corpName;
    } else {
        formDing = {
            corpId: '',
            agentId: '',
            appKey: '',
            appSecret: '',
            appCode: ''
        };
    }

    if (data.wcw) {
        formWX = data.wcw;
    } else {
        formWX = {
            corpName: '',
            appName: '',
            appCode: '',
            corpSecret: ''
        };
    }

    return { formDing, formWX };
};

export const updateMsgService = async (form) => {
    const dtForm = { channel: 'dt', ...form.formDing };
    const wcwForm = { channel: 'wcw', ...form.formWX };
    await axios.post(`/api/ops/config/common/message/update`, {
        dingtk: dtForm,
        wcw: wcwForm
    });
};

export const testMsgService = async (appCode, channel, phoneNum) => {
    let params = {
        appCode: appCode,
        channel: channel,
        phoneNum: phoneNum
    };
    return await axios.post(`/api/ops/config/common/test/message`, null, { params: params });
};
