<template>
    <div class="the-form-section">
        <h2 class="the-form-section__title">{{ title }}</h2>

        <main class="the-form-section__body">
            <slot></slot>
        </main>
    </div>
</template>

<script>
export default {
    name: 'TheFormSection',

    props: {
        title: {
            type: String,
            default: '表单'
        }
    }
};
</script>

<style scoped lang="scss">
.the-form-section {
    border-bottom: 1px solid #ddd;
    margin-bottom: 32px;

    &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
    }

    &__title {
        font-size: 16px;
        color: #333333;
        line-height: 32px;
        margin-bottom: 24px;
    }
}
</style>
