'use strict';

import axios from '../../../utils/axios';

const appName = 'common-location-service';

export const queryLocationConfig = async () => {
    const params = { appName };
    const data = await axios.get(`/api/ops/config/common/page/configGet`, { params });
    const { 'city.gaode.url': url, 'city.gaode.ak': webAppKey, 'web.gaode.ak': jsAppKey } = data;
    return { url, webAppKey, jsAppKey };
};

export const updateLocationConfig = async (form) => {
    const { webAppKey, jsAppKey } = form;
    const data = {
        appName,
        map: {
            'city.gaode.ak': webAppKey,
            'web.gaode.ak': jsAppKey
        }
    };
    return await axios.post(`/api/ops/config/common/page/configUpdate`, data);
};
