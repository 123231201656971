<template>
    <div class="hf-wrapper">
        <isc-form :model="form" label-width="130px" label-position="left">
            <isc-form-item label="Url">
                <isc-input
                    v-model="form.hfUrl"
                    disabled
                    placeholder="示例: https://way.jd.com/jisuapi/weather"
                ></isc-input>
            </isc-form-item>
            <isc-form-item label="AppKey">
                <isc-input v-model="form.hfAppkey" placeholder="示例：123456" @change="handleChange"></isc-input>
            </isc-form-item>
            <isc-form-item>
                <isc-button type="plain" icon="isc-icon-setting" :disabled="testDisabled" @click="handleTest"
                    >测试</isc-button
                >
            </isc-form-item>
        </isc-form>
    </div>
</template>

<script>
import { testWeather } from './service';

export default {
    name: 'Index',

    props: {
        formProp: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },

    data() {
        return {
            form: {
                hfAppkey: '',
                hfUrl: '',
                hfCityUrl: ''
            }
        };
    },

    computed: {
        testDisabled() {
            return !(this.form.hfAppkey && this.form.hfUrl && this.form.hfCityUrl);
        }
    },

    watch: {
        formProp: {
            immediate: true,
            handler(val) {
                this.form.hfAppkey = val.hfAppkey;
                this.form.hfUrl = val.hfUrl;
                this.form.hfCityUrl = val.hfCityUrl;
            }
        },
        form: {
            handler(oldVal, newVal) {},
            deep: true,
            immediate: true
        }
    },

    methods: {
        async handleTest() {
            try {
                await testWeather();
                this.$message.success('测试连接成功');
            } catch (error) {
                this.$message.error('测试连接失败');
            }
        },

        handleChange() {
            this.$emit('onchange', this.form);
        }
    }
};
</script>

<style lang="scss" scoped>
.hf-wrapper {
    width: 100%;
    .isc-form {
        width: 400px;
        padding: 16px;
        &-item {
            margin-bottom: 24px;
            ::v-deep .isc-form-item__label {
                color: #333333 !important;
            }

            .isc-button--text {
                ::v-deep i {
                    font-size: 16px;
                }
            }
        }
    }

    .isc-footer {
        border-top: 1px solid #e8e8e8;
    }
}
</style>
