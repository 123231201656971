<template>
    <the-form-layout :init-method="getWeatherConfig" :sure-method="updateWeatherConfig">
        <template #default="{ data: form }">
            <isc-form-item label="天气源">
                <isc-tabs v-model="form.defaultKey" type="card">
                    <isc-tab-pane label="京东万象" name="JDWX">
                        <JD :form-prop="form.jdForm" @onchange="(e) => handleJDChange(e, form)" />
                    </isc-tab-pane>
                    <isc-tab-pane label="和风天气" name="HFTQ">
                        <HF :form-prop="form.hfForm" @onchange="(e) => handleHFChange(e, form)" />
                    </isc-tab-pane>
                    <isc-tab-pane label="心知天气" name="XZTQ">
                        <XZ :form-prop="form.xzForm" @onchange="(e) => handleXZChange(e, form)" />
                    </isc-tab-pane>
                </isc-tabs>
            </isc-form-item>
        </template>
    </the-form-layout>
</template>

<script>
import TheFormLayout from '../components/the-form-layout';
import JD from './JD';
import HF from './HF';
import XZ from './XZ';
import { getWeatherConfig, updateWeatherConfig } from './service';

export default {
    name: 'WeatherConfig',

    components: {
        TheFormLayout,
        JD,
        HF,
        XZ
    },

    data() {
        return {
            getWeatherConfig,
            updateWeatherConfig
        };
    },

    methods: {
        handleJDChange(jdForm, form) {
            form.jdForm = jdForm;
        },

        handleHFChange(hfForm, form) {
            form.hfForm = hfForm;
        },

        handleXZChange(xzForm, form) {
            form.xzForm = xzForm;
        }
    }
};
</script>

<style lang="scss" scoped>
.sms-config-wrapper {
    width: 100%;

    .isc-form {
        width: 400px;
        ::v-deep .isc-tabs__item {
            width: 80px;
            text-align: center;
        }

        &-item {
            ::v-deep .isc-form-item__label {
                color: #333333 !important;
            }

            .isc-textarea {
                ::v-deep .isc-textarea__inner {
                    height: 95px;
                    border: none;
                    background-color: #f5f5f5;
                }
            }

            .isc-button--text {
                color: #047aff;
                line-height: 18px;
                display: inline-flex;
                ::v-deep i {
                    font-size: 16px;
                }
            }
            .isc-table {
                color: #333333;
                outline: 1px solid rgba(0, 0, 0, 0.15);
                overflow: visible;
                &::before {
                    height: 0px;
                }
                ::v-deep {
                    .isc-table__header {
                        &-wrapper {
                            overflow: visible;
                        }
                    }
                    .isc-table__body {
                        &-wrapper {
                            overflow: visible;
                        }
                        td .cell {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .delete-btn {
                    color: #fe694a;
                    &.is-disabled {
                        color: rgba(0, 0, 0, 0.25);
                    }
                }
            }

            // &:last-of-type {
            // }
        }
        .isc-tab-pane {
            background: rgba(0, 0, 0, 0.04);
        }
        ::v-deep .isc-tabs__content {
            width: 800px;
        }
    }

    .isc-footer {
        border-top: 1px solid #e8e8e8;
    }
}
</style>
