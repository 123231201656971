<template>
    <div class="emay-wrapper">
        <isc-form :model="form" label-width="130px" label-position="left">
            <isc-form-item label="Access-Key-Id">
                <isc-input v-model="form.accessKeyId" placeholder="示例：123456"></isc-input>
            </isc-form-item>

            <isc-form-item label="Access-Key-Secret">
                <isc-input v-model="form.accessKeySecret" placeholder="示例：123456"></isc-input>
            </isc-form-item>

            <isc-form-item label="短信模板">
                <isc-button type="text" icon="isc-icon-plus" @click="onAdd">新增模板</isc-button>
                <isc-table :data="copyTemplateData" style="width: 800px" stripe>
                    <isc-table-column prop="name" label="模板名称"> </isc-table-column>
                    <isc-table-column prop="templateId" label="Key"> </isc-table-column>
                    <!-- <isc-table-column label="内容" width="180">
                        <template slot-scope="scope">
                            <isc-tooltip
                                class="item"
                                effect="dark"
                                :content="scope.row.content"
                                :visible-arrow="false"
                                placement="top-start"
                            >
                                <span>{{ scope.row.content }}</span>
                            </isc-tooltip>
                        </template>
                    </isc-table-column> -->
                    <isc-table-column prop="operation" label="操作" width="230">
                        <template slot-scope="scope">
                            <isc-button type="text" @click="onTest(scope.row)">测试</isc-button>
                            <span class="mx-2" style="opacity: 0.8; width: 1px; margin: 12px">|</span>
                            <isc-button type="text" @click="preview(scope.row)">预览</isc-button>
                            <span class="mx-2" style="opacity: 0.8; width: 1px; margin: 12px">|</span>
                            <isc-button
                                type="text"
                                @click="onEdit(scope.row, scope.$index)"
                                :disabled="scope.row.isInside === 'true'"
                            >
                                编辑
                            </isc-button>
                            <span class="mx-2" style="opacity: 0.8; width: 1px; margin: 12px">|</span>
                            <isc-button
                                class="delete-btn"
                                type="text"
                                @click="onDelete(scope.row, scope.$index)"
                                :disabled="scope.row.isInside === 'true'"
                            >
                                删除
                            </isc-button>
                        </template>
                    </isc-table-column>
                </isc-table>
            </isc-form-item>
        </isc-form>
        <TheSmsDialog ref="dialogEmail" :form-prop="formProp" :title="title" @sure="onSure" :is-add="isAdd" />
        <the-preview-dialog ref="previewDialog"></the-preview-dialog>
    </div>
</template>

<script>
import TheSmsDialog from './components/the-sms-dialog';
import ThePreviewDialog from './components/the-preview-dialog';
import { testSms } from './service';

export default {
    name: 'Emay',

    components: {
        TheSmsDialog,
        ThePreviewDialog
    },

    props: {
        smsData: {
            type: Object,
            default: () => ({})
        },
        templateData: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            form: {},
            formProp: { name: '', key: '', value: '' },
            title: '',
            copyTemplateData: [],
            isAdd: true
        };
    },

    watch: {
        smsData: {
            immediate: true,
            handler() {
                this.form = this.smsData;
            }
        },

        templateData: {
            immediate: true,
            handler(val) {
                this.copyTemplateData = val;
            }
        }
    },

    methods: {
        onSure({ index, name, key, value }) {
            const tarTemplate = this.copyTemplateData.find((item) => {
                return item.templateId === key;
            });

            if (!this.isAdd && tarTemplate) {
                tarTemplate.name = name;
                tarTemplate.value = value;
                tarTemplate.templateId = key;
                return;
            } else {
                if (tarTemplate) {
                    this.$message.error(`已存在Key值为${key}的模板`);
                    return;
                }
                this.copyTemplateData.unshift({
                    name,
                    value,
                    templateId: key,
                    isInside: 'false'
                });
            }
        },
        preview(item) {
            this.$refs.previewDialog.open(item.value);
        },
        onDelete(item, index) {
            this.$confirm('确认要删除该短信模板吗？', '删除短信模板', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            })
                .then((result) => {
                    this.copyTemplateData.splice(index, 1);
                })
                .catch((err) => {});
        },
        onEdit({ name, value, templateId }, index) {
            this.title = '编辑短信模板';
            this.isAdd = false;
            this.formProp = { index, name, value, key: templateId };
            this.$refs.dialogEmail.dialogVisible = true;
        },
        onAdd() {
            this.title = '新增邮件模板';
            this.isAdd = true;
            this.formProp = { index: this.copyTemplateData.length, name: '', key: '', value: '' };
            this.$refs.dialogEmail.dialogVisible = true;
        },
        onTest(row) {
            this.$prompt('手机号码', '测试', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(async ({ value }) => {
                try {
                    const res = await testSms(value, row.templateId);
                    if (res) {
                        this.$message.success('测试连接成功');
                    }
                } catch (error) {
                    this.$message.error('测试连接失败');
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.emay-wrapper {
    width: 100%;
    .isc-form {
        width: 400px;
        padding: 16px;
        &-item {
            margin-bottom: 24px;
            ::v-deep .isc-form-item__label {
                color: #333333 !important;
            }

            .isc-button--text {
                padding-left: 0px;
                ::v-deep i {
                    font-size: 16px;
                }
            }

            ::v-deep .isc-table {
                outline: 1px solid rgba(0, 0, 0, 0.15);
                color: #333333;
                overflow: visible;
                &::before {
                    height: 0px;
                }
                .isc-table__header {
                    &-wrapper {
                        overflow: visible;
                    }
                }
                .isc-table__body {
                    &-wrapper {
                        overflow: visible;
                    }
                    .cell {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                .delete-btn {
                    color: #fe694a;
                    &.is-disabled {
                        color: rgba(0, 0, 0, 0.25);
                    }
                }
            }

            &:last-of-type {
                width: 730px;
                margin: 0;
            }
        }
    }

    .isc-footer {
        border-top: 1px solid #e8e8e8;
    }
}
</style>
