<template>
    <the-form-layout :init-method="getMsgService" :sure-method="updateMsgService">
        <template #default="{ data: { formDing, formWX } }">
            <isc-form :model="formDing" label-width="100px" label-position="left">
                <the-form-section title="钉钉">
                    <isc-form-item class="w400" label="公司名称 CorpId">
                        <isc-input
                            v-model="formDing.corpId"
                            placeholder="示例：ding04f0f6ce504f11c424f2f5cc6abecb85"
                        ></isc-input>
                    </isc-form-item>
                    <isc-form-item class="w400" label="应用 idAgentId">
                        <isc-input v-model="formDing.agentId" placeholder="示例：349851285"></isc-input>
                    </isc-form-item>
                    <isc-form-item class="w400" label="appKey">
                        <isc-input v-model="formDing.appKey" placeholder="示例：ding1wcyhzqggqxls1s7"></isc-input>
                    </isc-form-item>
                    <isc-form-item class="w400" label="appSecret">
                        <isc-input
                            v-model="formDing.appSecret"
                            placeholder="示例：rOOAzz8dpdOH3si7pHarQJlRX3ZEp2ikOW LP6ct6NGHaAw7W-tWXP7g63_djaLOt"
                        ></isc-input>
                    </isc-form-item>
                    <isc-form-item class="w400" label="appCode">
                        <isc-input v-model="formDing.appCode" placeholder="请输入appCode"></isc-input>
                    </isc-form-item>
                    <isc-form-item>
                        <isc-button
                            type="plain"
                            icon="isc-icon-setting"
                            :disabled="testDisabled1(formDing)"
                            @click="testDingMsg(formDing)"
                        >
                            测试
                        </isc-button>
                    </isc-form-item>
                </the-form-section>
            </isc-form>
            <isc-form :model="formWX" label-width="100px" label-position="left">
                <the-form-section title="企业微信">
                    <isc-form-item class="w400" label="公司名称">
                        <isc-input v-model="formWX.corpName" placeholder="请输入公司名称"></isc-input>
                    </isc-form-item>
                    <isc-form-item class="w400" label="应用名称">
                        <isc-input v-model="formWX.appName" placeholder="请输入应用名称"></isc-input>
                    </isc-form-item>
                    <isc-form-item class="w400" label="appCode">
                        <isc-input v-model="formWX.appCode" placeholder="请输入appCode"></isc-input>
                    </isc-form-item>
                    <isc-form-item class="w400" label="corpSecret">
                        <isc-input v-model="formWX.corpSecret" placeholder="请输入corpSecret"></isc-input>
                    </isc-form-item>
                    <isc-form-item>
                        <isc-button
                            type="plain"
                            icon="isc-icon-setting"
                            :disabled="testDisabled2(formWX)"
                            @click="testWxMsg(formWX)"
                        >
                            测试
                        </isc-button>
                    </isc-form-item>
                </the-form-section>
            </isc-form>
        </template>
    </the-form-layout>
</template>

<script>
import TheFormLayout from '../components/the-form-layout';
import TheFormSection from '../components/the-form-section';
import { getMsgService, updateMsgService, testMsgService } from './service';

export default {
    name: 'MsgConfig',

    components: {
        TheFormSection,
        TheFormLayout
    },

    data() {
        return {
            getMsgService,
            updateMsgService
        };
    },

    methods: {
        testDisabled1(formDing) {
            return !(formDing.corpId && formDing.agentId && formDing.appKey && formDing.appSecret && formDing.appCode);
        },

        testDisabled2(formWX) {
            return !(formWX.corpName && formWX.appName && formWX.corpSecret && formWX.appCode);
        },

        async testDingMsg(formDing) {
            let res;
            try {
                res = await this.$prompt('手机号码', '测试', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                });
            } catch (err) {
                return;
            }

            try {
                await testMsgService(formDing.appCode, 'dt', res.value);
                this.$message.success('测试成功');
            } catch (error) {
                this.$message.error(error.data.appCode);
            }
        },

        async testWxMsg(formWX) {
            let res;
            try {
                res = await this.$prompt('手机号码', '测试', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                });
            } catch (err) {
                return;
            }

            try {
                await testMsgService(formWX.appCode, 'wcw', res.value);
                this.$message.success('测试成功');
            } catch (error) {
                this.$message.error(error.message);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.w400 {
    width: 400px;
}
</style>
