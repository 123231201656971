<template>
    <app-layout>
        <template #header>
            <toolbar v-show="toolbar" @settings="$refs.commonSettings.open()" />
            <common-settings ref="commonSettings"></common-settings>
        </template>
        <template #sidebar>
            <transition name="sidebar" tag="div">
                <sidebar v-show="sidebar"></sidebar>
            </transition>
        </template>
        <template #contain>
            <div class="flex-grow-1">
                <keep-alive>
                    <router-view v-if="$route.meta.keepAlive"></router-view>
                </keep-alive>
                <router-view v-if="!$route.meta.keepAlive"></router-view>
            </div>
        </template>
    </app-layout>
</template>

<script>
import AppLayout from './components/app/app-layout';
import Toolbar from './components/common/core-toolbar';
import Sidebar from './components/common/core-sidebar';
import CommonSettings from '@/views/common-settings';

export default {
    components: {
        CommonSettings,
        AppLayout,
        Toolbar,
        Sidebar
    },
    data: () => ({
        guideVisible: window.localStorage.getItem('isOld') !== '1',
        toolbarShow: true
    }),
    created() {
        if (this.$router.history.base + this.$router.history.current.path === 'desktop/login') {
            window.reload();
        }
        if (window.localStorage.getItem('dragList')) {
            this.$store.commit('drag/setDragDataList', JSON.parse(window.localStorage.getItem('dragList')));
        } else {
            window.localStorage.setItem('dragList', '{}');
        }
        this.$store.commit('drag/setDragDataList', JSON.parse(window.localStorage.getItem('dragList')));
        this.toolbarShow = top == self;
    },
    computed: {
        toolbar: {
            get: function () {
                return this.$store.state.app.toolbar;
            }
        },
        sidebar: function () {
            return this.$store.state.app.sidebar;
        }
    }
};
</script>
<style scoped>
.sidebar-enter,
.sidebar-leave-to {
    opacity: 1; /* 透明度 */
    transform: translateY(-100%);
}
.sidebar-enter-active,
.sidebar-leave-active {
    transition: all 0.3s linear;
}
::v-deep header {
    min-width: auto !important;
}
</style>
<style>
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    --webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);

    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #c1c1c1;

    --webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #c1c1c1;
}
</style>
