<template>
    <dialog-layout ref="dtlDialog" class="the-list-dialog" width="1005px" title="">
        <template>
            <div v-if="info" class="dtl">
                <div class="dtl-left">
                    <div class="dtl-left-top">
                        <isc-svg-icon
                            name="iscon-back"
                            size="22"
                            class="dtl-left-top__icon"
                            @click="closeFun"
                        ></isc-svg-icon>
                        <span>{{ info.messageTitle }}</span>
                    </div>
                    <span class="dtl-left__desc">来源应用：{{ info.messageAppName }}</span>
                    <span class="dtl-left__desc"
                        >级别：{{
                            info.messageLevel === 1
                                ? '提示'
                                : info.messageLevel === 2
                                ? '重要'
                                : info.messageLevel === 3
                                ? '紧急'
                                : ''
                        }}</span
                    >
                    <span class="dtl-left__desc">时间：{{ formatTime(info.sendTime) }}</span>
                    <div class="dtl-left__content">
                        {{ info.messageContent }}
                        <a v-if="info.url" :href="info.url" class="btn"
                            >点击查看<isc-svg-icon name="iscon-angle-right" size="12px"></isc-svg-icon
                        ></a>
                    </div>
                    <img src="@/assets/line1.png" class="dtl-left__line" />
                </div>
                <div class="dtl-right">
                    <!-- <div class="dtl-right-item">
                        <img src="@/assets/line2.png" class="dtl-right-item__line2" />
                        <p class="dtl-right-item__title">下一条（未读）</p>
                        <img src="@/assets/line3.png" class="dtl-right-item__line3" />
                        <p class="dtl-right-item__name">标题</p>
                        <p class="dtl-right-item__desc">
                            这里是内容这里是内容这里是内容这里是内容这里是内容这里是内容这里是内容这里是内容这里是内容这里是内容这里是内容这里是内容
                        </p>
                    </div> -->
                </div>
            </div>
        </template>
        <template #footer>
            <div></div>
        </template>
    </dialog-layout>
</template>

<script>
import { messageDtl, handleMessage } from '@/service/common.js';
export default {
    name: 'DtlDialog',

    components: {},

    data() {
        return {
            info: null
        };
    },

    methods: {
        async initMethod(id) {
            let data;
            try {
                data = await messageDtl(id);
            } catch (err) {
                console.log(err);
                return;
            }
            this.info = data;
            try {
                await handleMessage(data);
            } catch (err) {
                console.log(err);
                return;
            }
            this.$refs.dtlDialog.open();
        },
        closeFun() {
            this.$emit('return');
            this.$refs.dtlDialog.close();
        },
        formatTime(time) {
            return time.substring(0, 19).replace('T', ' ');
        }
    }
};
</script>

<style scoped lang="scss">
.dtl {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    &-left {
        width: 676px;
        &-top {
            font-size: 24px;
            font-weight: 500;
            color: #333333;
            margin-bottom: 16px;
            &__icon {
                color: #047aff;
                cursor: pointer;
                margin-right: 13px;
            }
        }
        &__desc {
            font-size: 14px;
            color: #999999;
            margin-right: 16px;
        }
        &__content {
            min-height: 550px;
            font-size: 16px;
            color: #333333;
            line-height: 24px;
            margin: 16px 0 35px 0;
        }
        &__line {
            display: block;
            height: 6px;
            margin: 0 auto 50px;
        }
    }
    &-right {
        width: 241px;
        &-item {
            padding: 16px;
            cursor: pointer;
            border: 1px solid #ddd;
            position: relative;
            &__line2 {
                height: 2px;
                position: absolute;
                top: -1px;
                left: 0;
            }
            &__title {
                font-size: 14px;
                font-weight: 500;
                color: #333333;
                margin-bottom: 16px;
            }
            &__line3 {
                height: 1px;
                margin-bottom: 16px;
            }
            &__name {
                font-size: 14px;
                font-weight: 500;
                color: #333333;
                margin-bottom: 8px;
            }
            &__desc {
                font-size: 12px;
                color: #666666;
                line-height: 18px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }
        }
    }
}
.btn {
    font-size: 16px;
    color: #047aff;
    cursor: pointer;
    margin-left: 5px;
}
::v-deep .isc-dialog {
    padding: 24px;
    &__body {
        padding: 0;
    }
}
</style>
