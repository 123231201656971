import axios from '@/utils/axios';
import store from '@/store';
import AppManager from '@/utils/app-manager.js';
class Reporter {
    constructor(config) {
        this.userVisitCache = new Map();
        this.logCache = new Map();
        this.initTimer(config.interval);
        this.currentApp = null;
        this.currentPage = null;
        this.userActionUrl = '/api/core/process/cockpit/collect/userBehavior';
        this.userLogUrl = '/api/core/process/log/push';
        this.appManager = AppManager.getInstance();
    }

    initTimer(time) {
        setInterval(() => {
            this.doReportVisitAction();
            this.doReportLogAction();
            this.clearUserVisitCache();
        }, time);
    }

    async doReportVisitAction() {
        if (this.userVisitCache.size === 0) {
            return;
        }
        const reqBody = [];
        // TODO 后期处理失败了，把上次失败的数据一起提交
        // const clone = new Map(this.userVisitCache);
        for (let [key, value] of this.userVisitCache.entries()) {
            let obj = {
                uri: key,
                count: value
            };
            reqBody.push(obj);
        }
        await axios.post(this.userActionUrl, reqBody, { dontHint: true });
    }

    async doReportLogAction() {
        if (this.logCache.size === 0) {
            return;
        }
        let reqBody = [];
        for (let [key, value] of this.logCache.entries()) {
            const [app, logFile] = key.split('|');
            const obj = {
                logFile,
                app: app,
                logs: value
            };
            reqBody.push(obj);
        }
        axios.post(this.userLogUrl, reqBody);
    }

    clearUserVisitCache() {
        if (!this.userVisitCache) {
            this.userVisitCache = new Map();
        }
        if (!this.logCache) {
            this.logCache = new Map();
        }
        this.userVisitCache.clear();
        this.logCache.clear();
    }

    // 记录行为，分发一下各种行为
    record(action) {
        if (action.type === 'visit') {
            this.recordVisit(action);
        } else if (action.type === 'click') {
            this.recordClick(action);
        } else if (action.type === 'log') {
            this.recordLog(action);
        }
    }

    recordVisit(visitAction) {
        const code = this.computeCode(visitAction);
        this.updateCurrentState(visitAction);
        this.updateVisitCache(code);
    }

    recordClick(clickAction) {
        const code = this.currentApp + '|' + this.currentPage + '|' + clickAction.actionCode;

        this.updateVisitCache(code);
    }

    recordLog(userLogAction) {
        const code = this.currentApp + '|' + userLogAction.logTag;
        const content = this.computeLogContent(userLogAction);

        this.updateLogCache(code, content);
    }

    computeLogContent(userLogAction) {
        let content = userLogAction.content.replaceAll(/｜/g, '|');
        let userName = store.state.sso?.userName || 'unknown';
        let time = Date.now();
        let appCode = this.appManager.currentAppCode;
        if (content.startsWith('|')) content = content.substirng(1);
        return `${time}|${userName}|${appCode}|${content}`;
    }

    updateVisitCache(code) {
        if (!this.userVisitCache.get(code)) {
            this.userVisitCache.set(code, 1);
        }

        this.userVisitCache.set(code, this.userVisitCache.get(code) + 1);
    }

    updateLogCache(code, content) {
        if (!this.logCache.get(code)) {
            this.logCache.set(code, []);
        }
        let logs = this.logCache.get(code);
        logs.push(content);

        this.logCache.set(code, logs);
    }

    computeCode(visitAction) {
        // 0 代表 actionCode 没有
        return visitAction.appCode + '|' + visitAction.pageCode + '|' + '0';
    }

    updateCurrentState(visitAction) {
        this.currentApp = visitAction.appCode;
        this.currentPage = visitAction.pageCode;
    }
}

const reporter = new Reporter({ interval: 30000 });

export { reporter };
