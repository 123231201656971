<template>
    <div class="nav-menu-wrapper flex-shrink-0">
        <isc-menu
            :default-active="active"
            mode="vertical"
            background-color="transparent"
            text-color="#333333"
            active-text-color="#047AFF"
        >
            <isc-menu-item v-for="nav in navs" :key="nav[0]" :index="nav[0]" @click="active = nav[0]">
                {{ nav[1] }}
            </isc-menu-item>
        </isc-menu>
    </div>
</template>

<script>
const navs = Object.freeze([
    ['BasicConfig', '基础配置'],
    ['EmailConfig', '邮箱配置'],
    ['SmsConfig', '短信配置'],
    ['WeatherConfig', '天气服务'],
    ['LbsConfig', 'LBS API 配置'],
    ['MsgConfig', '消息服务']
]);

export default {
    name: 'NavMenu',

    model: {
        prop: 'value',
        event: 'update:value'
    },

    props: {
        value: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            navs
        };
    },

    computed: {
        active: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('update:value', value);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.nav-menu-wrapper {
    user-select: none;
    width: 200px;
    border-right: 1px solid #ddd;

    ::v-deep {
        .isc-menu {
            padding: 0;
            border-right: 0;

            &-item {
                border-radius: 0;
                font-size: 14px;
                height: 52px;
                line-height: 52px;
                margin: 0;
                padding-left: 48px;

                &:hover {
                    background: #f2f2f2 !important;
                }
            }
        }
    }
}
</style>
