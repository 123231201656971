<template>
    <isc-dialog class="the-preview-dialog" title="邮件模板预览" :visible.sync="visible" append-to-body>
        <iframe class="the-preview-dialog__iframe" ref="iframe" :style="iframeStyle"></iframe>

        <template #footer>
            <isc-button type="primary" @click="visible = false">关闭</isc-button>
        </template>
    </isc-dialog>
</template>

<script>
export default {
    name: 'ThePreviewDialog',

    data() {
        return {
            visible: false,
            height: 300
        };
    },

    computed: {
        iframeStyle() {
            const { height } = this;

            return {
                height: height + 'px'
            };
        }
    },

    methods: {
        async open(html) {
            this.visible = true;
            await this.$nextTick();
            const doc = this.$refs.iframe.contentDocument;
            doc.write(html);
            doc.close();
            const htmlEl = doc.documentElement;
            this.height = Math.max(300, htmlEl.offsetHeight, htmlEl.scrollHeight);
        }
    }
};
</script>

<style scoped lang="scss">
.the-preview-dialog {
    &__iframe {
        border: 0;
        display: block;
        width: 100%;
        background: white;
    }
}
</style>
