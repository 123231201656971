import Vue from 'vue';
import {
    DialogLayout,
    TableLayout,
    TableColumn,
    TableOperatorButton,
    TableOperatorSearch,
    TableFilterRadio
} from '@isyscore/admin-ui';

[DialogLayout, TableLayout, TableColumn, TableOperatorButton, TableOperatorSearch, TableFilterRadio].forEach((comp) =>
    Vue.use(comp)
);
