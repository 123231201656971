<template>
    <div class="the-email-dialog">
        <isc-dialog :title="title" :visible.sync="dialogVisible" append-to-body width="800px">
            <isc-form :model="form" label-width="100px" label-position="left" class="the-email-dialog__form">
                <isc-form-item label="模板名称">
                    <isc-input v-model="form.name"></isc-input>
                </isc-form-item>
                <isc-form-item label="Key">
                    <isc-input v-model="form.key"></isc-input>
                </isc-form-item>
                <isc-form-item label="内容">
                    <editor-bar v-model="form.value" :is-clear="isClear" @change="change"></editor-bar>
                </isc-form-item>
            </isc-form>
            <span slot="footer" class="dialog-footer">
                <isc-button @click="dialogVisible = false">取 消</isc-button>
                <isc-button type="primary" @click="handleSure">确 定</isc-button>
            </span>
        </isc-dialog>
    </div>
</template>

<script>
import EditorBar from '../../components/wangEditor/index';
export default {
    name: 'Index',
    components: { EditorBar },
    props: {
        formProp: {
            type: Object,
            default: () => {
                return {};
            }
        },
        title: {
            type: String,
            default: ''
        }
    },
    watch: {
        formProp: {
            handler(val) {
                this.form = val;
            },
            deep: true
        }
    },
    data() {
        return {
            form: {
                name: '',
                key: '',
                value: ''
            },
            isClear: false,
            dialogVisible: false
        };
    },
    methods: {
        change(val) {},
        handleSure() {
            this.dialogVisible = false;
            this.$emit('onsure', this.form);
        }
    }
};
</script>

<style lang="scss" scoped>
.the-email-dialog {
    .isc-form.the-email-dialog__form {
        width: auto;
    }
    .isc-form {
        .form-title {
            font-weight: 600;
            ::v-deep .isc-form-item__label {
                font-size: 16px;
            }
        }
        &-item {
            ::v-deep .isc-form-item__label {
                color: #333333 !important;
            }

            .isc-textarea {
                ::v-deep .isc-textarea__inner {
                    height: 95px;
                    border: none;
                    background-color: #f5f5f5;
                }
            }

            .isc-button--text {
                ::v-deep i {
                    font-size: 16px;
                }
            }
        }
    }
}

.templateMsgBox {
    width: auto;
}
</style>
