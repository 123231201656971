'use strict';

import axios from '../../../utils/axios';

export const getEmailConfig = async () => {
    let params = {
        appName: 'common-mail-service'
    };
    const data = await axios.get(`/api/ops/config/common/page/configGet`, { params });
    return {
        username: data['spring.mail.username'],
        password: data['spring.mail.password'],
        protocol: data['spring.mail.protocol'],
        host: data['spring.mail.host'],
        signKey: data['mail-config.signKey'],
        mailTemplate: JSON.parse(data['mail-template.jsonData'])
    };
};

export const updateEmailConfig = async (form) => {
    const map = {
        'spring.mail.username': form.username,
        'spring.mail.password': form.password,
        'spring.mail.protocol': form.protocol,
        'spring.mail.host': form.host,
        'mail-config.signKey': form.signKey,
        'mail-template.jsonData': JSON.stringify(form.mailTemplate)
    };
    let params = {
        appName: 'common-mail-service',
        map
    };
    return await axios.post(`/api/ops/config/common/page/configUpdate`, params);
};

export const testEmail = async (params) => {
    return await axios.post(`/api/ops/config/common/test/mail`, {
        appName: 'common-mail-service',
        map: {
            'spring.mail.username': params.username,
            'spring.mail.password': params.password,
            'spring.mail.protocol': params.protocol,
            'spring.mail.host': params.host
        }
    });
};
