/*
 * @Author: your name
 * @Date: 2020-09-08 10:57:21
 * @LastEditTime: 2020-09-11 10:40:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /isc-desktop-ui/src/views/common-settings/basic-config/service.js
 */
'use strict';
import { bus } from '../../../bus/bus';
import axios from '../../../utils/axios';
import store from '../../../store';

export const getBasicConfig = async (data) => {
    let params = {
        ...data,
        appName: 'isc-sys-config-service'
    };
    return await axios.get(`/api/ops/config/common/page/configGet`, {
        params: {
            ...params,
            token: store.state.sso.token || ''
        }
    });
};

export const updateBasicConfig = async (data) => {
    let params = {
        appName: 'isc-sys-config-service',
        map: { ...data }
    };
    const res = await axios.post(`/api/ops/config/common/page/configUpdate`, params);
    store.state.app.user.companyDetail = data;
    bus.$emit('update-basic-config');
    return res;
};

export async function queryCanEdit(ids) {
    return axios.post('/api/core/license/desc/additional', { ids: ids });
}
